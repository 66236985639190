import React, { useEffect } from 'react';
import { Button, Input } from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import * as configurationService from '../../services/configuration';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { useTranslation } from 'react-i18next';

const ConfigurationEdit = () => {
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const formikAccumulation = useFormik({
        initialValues: {
            accumulationLimit: '',
        },
        validationSchema: Yup.object({
            accumulationLimit: Yup.number()
                .typeError(t('settings.label.onlyNumber'))
                .min(1, t('settings.label.oneCaracter'))
                .required(t('settings.label.accumulationLimitRequired')),
        }),
        onSubmit: (values) => {
            saveDataAccumulation(values);
        }
    });
    const formikAppData = useFormik({
        initialValues: {
            termsConditionsUrl: '',
            noticeOfPrivacyURL: '',
        },
        validationSchema: Yup.object({
            termsConditionsUrl: Yup.string()
                .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?([ A-Za-z0-9_@./#&+-]*)?$/,
                    t('settings.label.validateUrlTC')
                )
                .required(t('settings.label.accumulationLimitRequired')),
            noticeOfPrivacyURL: Yup.string()
                .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?([ A-Za-z0-9_@./#&+-]*)?$/,
                    t('settings.label.validateUrlTC')
                )
                .required(t('settings.label.accumulationLimitRequired')),
        }),
        onSubmit: (values) => {
            saveData(values);
        }
    });

    useEffect(() => {
        dispatch(setLoading(true));
        configurationService.getAppData()
            .then((response) => {
                formikAppData.setValues({ ...response.data })
                formikAccumulation.setValues({ ...response.data })
                dispatch(setLoading(false));
            }).catch(error => {
                dispatch(setLoading(false));
                console.log(error)
            })
    }, [])


    const saveData = async (data) => {
        try {
            dispatch(setLoading(true));
            await configurationService.updateAppData(data)
            toast.success(t('setting.label.settingEdit'));
            dispatch(setLoading(false));
        } catch (error) {
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
            dispatch(setLoading(false));
        }
    }

    const saveDataAccumulation = async (data) => {
        try {
            dispatch(setLoading(true));
            await configurationService.updateAccumulation(data.accumulationLimit)
            toast.success(t('settings.label.settingConfigPoints'));
            dispatch(setLoading(false));
        } catch (error) {
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
            dispatch(setLoading(false));
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="card">
                            <div className="card-header -p2">
                                <h3 className="text-danger font-weight-bold">{t('settings.label.configurationPoints')}</h3>
                            </div>
                            <div className="p-3">
                                <form className="form-horizontal" onSubmit={formikAccumulation.handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <div className="form-group row">
                                                <label>{t('settings.label.accumulationLimit')}</label>
                                                <Input
                                                    value={formikAccumulation.values.accumulationLimit}
                                                    formik={formikAccumulation}
                                                    formikFieldProps={formikAccumulation.getFieldProps('accumulationLimit')}
                                                    type="text"
                                                    placeholder={t('settings.label.accumulationLimit')} />
                                            </div>
                                            <div className="row p-2 pt-4">
                                                <div className="col-4">
                                                    <Link
                                                        to={`/`}
                                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                                </div>
                                                <div className="col-sm-8">
                                                    <Button
                                                        className="w-10"
                                                        type="submit"
                                                        className="btn btn-danger" > {t('general.buttons.saveChanges')} </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-8">
                        <div className="card ">
                            <div className="card-header -p2">
                                <h3 className="text-danger font-weight-bold">{t('settings.label.configurationsUrls')}</h3>
                            </div>
                            <div className="p-3">
                                <form className="form-horizontal" onSubmit={formikAppData.handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <div className="form-group row">
                                                <label>{t('settings.label.noticePrivacy')}</label>
                                                <Input
                                                    value={formikAppData.values.noticeOfPrivacyURL}
                                                    formik={formikAppData}
                                                    type="text"
                                                    formikFieldProps={formikAppData.getFieldProps('noticeOfPrivacyURL')}
                                                    placeholder={t('settings.label.noticePrivacy')} />
                                            </div>
                                            <div className="form-group row">
                                                <label>{t('settings.label.termsAndConditions')}</label>
                                                <Input
                                                    value={formikAppData.values.termsConditionsUrl}
                                                    formik={formikAppData}
                                                    type="text"
                                                    formikFieldProps={formikAppData.getFieldProps('termsConditionsUrl')}
                                                    placeholder={t('settings.label.termsAndConditions')} />
                                            </div>
                                            <div className="row p-2 pt-4">
                                                <div className="col-4">
                                                    <Link
                                                        to={`/`}
                                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                                </div>
                                                <div className="col-sm-8">
                                                    <Button
                                                        className="w-10"
                                                        type="submit"
                                                        className="btn btn-danger" > {t('general.buttons.saveChanges')} </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConfigurationEdit;