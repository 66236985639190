import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ContentHeader, ExportToCsv, SmallBox } from '@components';
import { setLoading } from '@app/store/reducers/ui';
import * as reportsService from '../../services/reports';
import Api from '../../utils/axios'
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { customStyles } from '../../utils/table-config'

moment.locale('es-MX');

//import { DateRangePicker, DefinedRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
/*Don't delete chart.js and react-chart libraries*/
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart, Bar, Pie, Doughnut } from 'react-chartjs-2'
// Require Esperanto locale
import './Reports.scss';
import {
    Chart as ChartJS2,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

const locale = {
    "format": "MM/DD/YYYY",
    "separator": " - ",
    "applyLabel": "Filtrar",
    "cancelLabel": "Cancelar",
    "fromLabel": "From",
    "toLabel": "To",
    "customRangeLabel": "Custom",
    "weekLabel": "W",
    "daysOfWeek": [
        "Do",
        "Lu",
        "Ma",
        "Mi",
        "Ju",
        "Vi",
        "Sa"
    ],
    "monthNames": [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ],
    "firstDay": 1
}

const ranges = {
    'Hoy': [moment(), moment()],
    'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Ultimos 7 días': [moment().subtract(6, 'days'), moment()],
    'Ultimos 30 días': [moment().subtract(29, 'days'), moment()],
    'Este mes': [moment().startOf('month'), moment().endOf('month')],
    'Últtimo mes': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
}

const headersSkuTop = [
    { label: 'total', key: 'sum' },
    { label: 'producto', key: 'product.descripcion' }
]

const Reports = () => {
    const api = new Api();
    const [t] = useTranslation();
    const [downloadUsers, setDownloadUsers] = useState([])
    const [notificationsSend, setNotificationsSend] = useState([])
    const [ticketsRejected, setTicketsRejected] = useState([])
    const [topTenStore, setTopTenStore] = useState([])
    const [ticketsStore, setTicketsStore] = useState([{}])
    const [ageBebies, setAgeBebies] = useState([{}])
    const [storTicket, setStorTicket] = useState([{}])
    const [points, setPoints] = useState([])
    const [sku, setSku] = useState([])
    const [skuTop, setSkuTop] = useState([])
    const [startDate, setStartDate] = useState(moment().subtract(6, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const loading = useSelector(state => state.ui.loading)
    const dispatch = useDispatch();
    const [timer, setTimer] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSaveLoading, setSaveLoading] = useState(false);
    const columnsProduct = useMemo(() => [
        {
            name: <b>Id</b>,
            selector: row => row.product.id,
            sortable: true,
        },
        {
            name: <b>{ t('general.label.name')}</b>,
            selector: row => row.product.descripcion,
            sortable: true,
        },
        {
            name: <b></b>,
            selector: row => row.sum,
            sortable: true,
        },
    ]);
    const columnsStores = useMemo(() => [
        {
            name: <b>{ t('general.label.name')}</b>,
            selector: row => row.name,
            sortable: true,
        },
        {
            name: <b>{t('moduleReports.label.tickets')}</b>,
            selector: row => row.percentageAllTickets + "%",
            sortable: true,
        },
        {
            name: <b>{t('moduleReports.label.validTickets')}</b>,
            selector: row => row.percentageTicketsValid + "%",
            sortable: true,
        },
    ]);

    ChartJS2.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    useEffect(() => {
        handleFilterStatick();

    }, [])

    const handleFilterStatick = async () => {
        dispatch(setLoading(true));
        try {

            const users = await reportsService.filterUsers();
            setDownloadUsers(users.data)

            const babies = await reportsService.filterBabies();
            setAgeBebies(babies.data.ranges.rangos)

            setSaveLoading(true);
            setSaveLoading(false);

        } catch (error) {
            setSaveLoading(false);
            console.log(error.message || 'Failed');
            dispatch(setLoading(false));
        }

        setIsLoading(true)
    }

    useEffect(() => {
        handleFilterSKU()
    }, [endDate])

    const handleFilterSKU = async () => {
        dispatch(setLoading(true));

        const date1 = startDate.startOf('day').format('MM/DD/yyyy HH:mm:ss')
        const date2 = endDate.endOf('day').format('MM/DD/yyyy HH:mm:ss')
        try {


            const sendNotifications = reportsService.filterSeen(date1, date2);
            const ticketStore = reportsService.filterStore(date1, date2);
            const ticketsRejected = reportsService.filterRejected(date1, date2);
            const usersPoints = reportsService.filterPoints(date1, date2);
            const ticketSku = reportsService.filterSku(date1, date2);
            const ticketStoree = reportsService.ticketsStore(date1, date2);

            Promise.all([sendNotifications, ticketStore, ticketsRejected, usersPoints, ticketSku, ticketStoree]).then(function (values) {
                setNotificationsSend(values[0].data)
                setTicketsStore(values[1].data.estadosOrdenados)
                setTicketsRejected(values[2].data)
                setPoints(values[3].data)
                setSku(values[4].data.listPercentage)
                setSkuTop(values[4].data.list)
                setStorTicket(values[5].data.response)
                let tiendasOrdenadas = values[5].data.response.sort((a, b) => a.percentageAllTickets > b.percentageAllTickets).slice(1, 10);
                setTopTenStore(tiendasOrdenadas);
                dispatch(setLoading(false));
            });

            setSaveLoading(true);
            setSaveLoading(false);
        } catch (error) {
            setSaveLoading(false);
            console.log(error.message || 'Failed');
            dispatch(setLoading(false));
        }

        setIsLoading(true)
    }
    // Redemption

    const resultRedemption = Object.values(points).map(value => { return value });
    const redemption = {
        labels: [t('moduleReports.label.pointsForAll')],
        datasets: [{
            label: t('moduleReports.label.pointsRedemption'),
            data: resultRedemption,
            backgroundColor: 'rgb(75, 192, 192)',
            borderColor: 'black'
        }]

    }
    // Tickets Rejected

    const resultTicketRejected = Object.values(ticketsRejected).map(value => {
        return value
    });
    const ticketRejected = {
        labels: [t('moduleReports.label.totalRejectedTickets'), t('moduleReports.label.totalViewedTickets'), t('moduleReports.label.ticketsSent'),],
        datasets: [{
            data: resultTicketRejected,
            backgroundColor: ['rgb(75, 192, 192)', 'rgb(255, 99, 132)', 'rgb(53, 162, 235)'],
            borderColor: 'black'

        }]
    }
    // Notifications Send
    const resultPushNotifications = Object.values(notificationsSend).map(value => { return value });
    const pushNotifications = {
        labels: [t('moduleReports.label.totalNotificationsSent'), t('moduleReports.label.totalViewedNotifications'), t('moduleReports.label.notificationsViewed'),],
        datasets: [{
            backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgb(255, 99, 132)', 'rgb(53, 162, 235)'],
            borderColor: 'black',
            hoverBackgroundColor: 'rgba(75, 192, 192, 1)',
            data: resultPushNotifications
        }]
    }
    // Download Gender
    const resultDownloadGender = Object.values(downloadUsers).map(value => {
        return value
    });
    const downloadGender = {
        labels: [t('moduleReports.label.allUsers'), t('moduleReports.label.masculine'), t('moduleReports.label.feminine'), t('moduleReports.label.notSpecified')],
        datasets: [{
            label: t('moduleReports.label.allUsers'),
            data: resultDownloadGender,
            backgroundColor: ['rgb(75, 192, 192)', 'rgb(255, 99, 132)', 'rgb(53, 162, 235)', 'rgb(75, 192, 192)'],
            borderColor: 'black'
        }]

    }

    // Baby Age

    const resultLabelBaby = Object.values(ageBebies).map(value => {
        return value.label
    });
    const resultValueBaby = Object.values(ageBebies).map(value => {
        return value.value
    });

    const babyAge = {
        labels: resultLabelBaby,
        datasets: [{
            label: t('moduleReports.label.babyAge'),
            data: resultValueBaby,
            backgroundColor: ['rgb(75, 192, 192)', 'rgb(255, 99, 132)', 'rgb(53, 162, 235)', 'rgb(75, 192, 192)', 'rgb(53, 162, 235)']
        }]

    }

    const resultSumSKU = Object.values(sku).map(value => {
        return value.sum
    });

    const resultLabelSKU = Object.values(sku).map(value => {
        return value.product.descripcion
    });

    const bestSellerSKU = {
        labels: resultLabelSKU,
        datasets: [{
            label: t('moduleReports.label.bestSellingSKU'),
            data: resultSumSKU,
            backgroundColor: ['rgb(75, 192, 192)',
                'rgba(255, 61, 4, 0.2)',
                'rgba(248, 4, 255, 0.2)',
                'rgba(148, 17, 66, 0.2)',
                'rgba(81, 17, 148, 0.2)',
                'rgba(17, 133, 148, 0.2)',
                'rgba(17, 148, 58, 0.2)',
                'rgba(148, 116, 17, 0.2)',
                'rgba(255, 220, 4, 0.2)',
                'rgba(112, 153, 249, 0.2)']
        }]
    }
    // Tickets Store
    const resultNameState = Object.values(ticketsStore).map(value => {
        return value.name
    });
    const resultValue = Object.values(ticketsStore).map(value => {
        return value.value
    });
    const registrationArea = {
        labels: resultNameState,
        datasets: [{
            label: t('moduleReports.label.AreaActiveMost'),
            backgroundColor: ['rgb(75, 192, 192)',
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(249, 112, 247, 0.2)',
                'rgba(112, 249, 145, 0.2)',
                'rgba(229, 249, 112, 0.2)',
                'rgba(204, 153, 51, 0.2)',
                'rgba(199, 195, 188, 0.2)',
                'rgba(243, 117, 77, 0.2)',
                'rgba(243, 77, 185, 0.2)',
                'rgba(243, 77, 127, 0.2)',
                'rgba(243, 77, 82, 0.2)',
                'rgba(113, 243, 77, 0.2)',
                'rgba(74, 127, 60, 0.2)',
                'rgba(60, 127, 112, 0.2)',
                'rgba(60, 60, 127, 0.2)',
                'rgba(127, 60, 126, 0.2)',
                'rgba(255, 61, 4, 0.2)',
                'rgba(248, 4, 255, 0.2)',
                'rgba(148, 17, 66, 0.2)',
                'rgba(81, 17, 148, 0.2)',
                'rgba(17, 133, 148, 0.2)',
                'rgba(17, 148, 58, 0.2)',
                'rgba(148, 116, 17, 0.2)',
                'rgba(255, 220, 4, 0.2)',
                'rgba(112, 153, 249, 0.2)'],
            data: resultValue
        }]
    }

    const resultTicketStoreName = Object.values(storTicket).map(value => {
        return value.name
    });

    const resultTicketStoreData = Object.values(storTicket).map(value => {
        //console.log(value.percentageAllTickets)
        return value.percentageAllTickets
    });

    const ticketStore = {
        labels: resultTicketStoreName,
        datasets: [{
            backgroundColor: ['rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(249, 112, 247, 0.2)',
                'rgba(112, 249, 145, 0.2)',
                'rgba(229, 249, 112, 0.2)',
                'rgba(204, 153, 51, 0.2)',
                'rgba(199, 195, 188, 0.2)',
                'rgba(243, 117, 77, 0.2)'],
            data: resultTicketStoreData
        }]
    }


    const isDataCeroInTicketStore = () => {
        const tickets = storTicket.filter(store => store.percentageAllTickets > 0)
        if (tickets.length > 0)
            return false;

        return true;
    }

    const isDataCeroState = () => {
        const states = ticketsStore.filter(state => state.value > 0);
        if (states.length > 0)
            return false;

        return true;
    }
    const isDataCeroBaby = () => {
        const baby = ageBebies.filter(state => state.value > 0);
        if (baby.length > 0)
            return false;

        return true;
    }

    const isNotificationCero = () => {
        if (notificationsSend.countAll > 0 && notificationsSend.countAllSeen > 0 && notificationsSend.percentageNotifications > 0)
            return false;

        return true;
    }

    const isDataTicketsCero = () => {
        if (ticketsRejected.countAll > 0 && ticketsRejected.countAllSeen > 0 && ticketsRejected.percentageTickets > 0)
            return false;

        return true;
    }

    const templateNoDataReport = () => {
        return (
            <div className="d-flex align-items-center justify-content-center message-no-data">
                <span>{t('moduleReports.label.noData')}</span>
            </div>
        )
    }


    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            title: {
                display: false,
                text: '',
            },
        },
    }
    const optionsLabelLeft = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'left',
            },
            title: {
                display: false,
                text: '',
            },
        },
    }

    const optionsNoLabel = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: false,
                text: '',
            },
        },
    }

    const handleCallback = async (start, end, label) => {
        setStartDate(start);
        setEndDate(end);
    }

    return (
        <div className="container-fluid"  >
            <ContentHeader title={t('menusidebar.label.reports')} />
            <form>
                <div className="form-row mb-5">
                    <div className="form-group col-md-6">
                        <label>{t('moduleReports.label.dateRange')}</label>
                        <DateRangePicker
                            initialSettings={{
                                startDate: startDate,
                                endDate: endDate,
                                maxDate: moment().endOf('month'),
                                locale: locale,
                                ranges: ranges,
                                timePickerSeconds: true
                            }}
                            onCallback={handleCallback}
                        >
                            <input type="text" className="form-control col-6" style={{ textAlign: 'center', }} />
                        </DateRangePicker>
                    </div>

                </div>
            </form>

            <div className="form-row">
                <div className="col-lg-3 col-6">
                    <SmallBox
                        percentage={points.points}
                        count={points.allPointsOfProducts}
                        title={t('moduleReports.label.pointsRedemption')}
                        type="info"
                        icon="ion-android-people"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        percentage={notificationsSend.percentageNotifications}
                        count={notificationsSend.countAll}
                        title={t('moduleReports.label.notificationsSeend')}
                        icon="ion-android-notifications"
                        type="success"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        percentage={ticketsRejected.percentageTickets}
                        count={ticketsRejected.countAll}
                        title={t('moduleReports.label.rejectedTickets')}
                        type="danger"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={downloadUsers.countAllUsers}
                        title={t('moduleReports.label.allUsers')}
                        type="warning"
                        icon="ion-android-people"
                        withPercentage
                    />
                </div>
            </div>

            <div className="form-row">
                <div className="form-group col-md-6">
                    <div className="card p-3" >
                        <div className="card-header -p2">
                            <span className="text-danger font-weight-bold fontSizeTitle">{t('moduleReports.label.babyAge')} </span>
                            {isDataCeroBaby() ? null :
                                <ExportToCsv data={ageBebies} fileName="babies_age.csv" />}
                        </div>
                        <div className="chart-content">
                            {isDataCeroBaby() ? templateNoDataReport() :
                                <Pie
                                    data={babyAge}
                                    options={options} />}
                        </div>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <div className="card p-3" >
                        <div className="card-header -p2">
                            <span className="text-danger font-weight-bold fontSizeTitle">{t('moduleReports.label.genderDownload')} </span>
                            {/*<ExportToCsv data={downloadUsers} fileName="users.csv" />*/}
                        </div>
                        <div className="chart-content">
                            <Bar
                                data={downloadGender}
                                options={optionsNoLabel} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-row">
                <div className="form-group col-md-6">
                    <div className="card p-3" >
                        <div className="card-header -p2">
                            <span className="text-danger font-weight-bold fontSizeTitle">{t('moduleReports.label.soldProducts')}</span>
                            {sku.length === 0 ? null :
                                <ExportToCsv data={sku} headers={headersSkuTop} fileName="sku.csv" />}
                        </div>
                        <div className="chart-content">
                            {sku.length === 0 ? templateNoDataReport() :
                                <Pie
                                    data={bestSellerSKU}
                                    options={options} />}
                        </div>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <div className="card p-3" >
                        <div className="card-header -p2">
                            <span className="text-danger font-weight-bold fontSizeTitle">{t('moduleReports.label.mostSoldProducts')}</span>
                            {skuTop.length === 0 ? null :
                                <ExportToCsv data={skuTop} headers={headersSkuTop} fileName="sku_top_10.csv" />}
                        </div>
                        <div className="chart-content">

                            {skuTop.length === 0 ? templateNoDataReport() :
                                <DataTable
                                    data={skuTop}
                                    customStyles={customStyles}
                                    highlightOnHover={true}
                                    columns={columnsProduct}
                                />}

                        </div>
                    </div>
                </div>
            </div>

            <div className="form-row">
                <div className="form-group col-md-6">
                    <div className="card p-3" >
                        <div className="card-header">
                            <span className="text-danger font-weight-bold fontSizeTitle">  {t('moduleReports.label.mostActiveStore')}</span>
                            {isDataCeroInTicketStore() ? null :
                                <ExportToCsv data={storTicket} fileName="stores_tickets.csv" />}

                        </div>
                        <div className="chart-content">
                            {isDataCeroInTicketStore() ? templateNoDataReport() :
                                <Pie
                                    data={ticketStore}
                                    options={options} />}
                        </div>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <div className="card p-3" >
                        <div className="card-header -p2">
                            <span className="text-danger font-weight-bold fontSizeTitle">{t('moduleReports.label.topTenShop')}</span>
                            {topTenStore.length === 0 ? null :
                                <ExportToCsv data={topTenStore} fileName="stores_tickets_top_10.csv" />}
                        </div>
                        <div className="chart-content">

                            <DataTable
                                data={topTenStore}
                                customStyles={customStyles}
                                highlightOnHover={true}
                                columns={columnsStores}
                            />

                        </div>
                    </div>
                </div>
            </div>

            <div className="form-row">

                <div className="form-group col-md-12">
                    <div className="card p-3" >
                        <div className="card-header -p2">
                            <span className="text-danger font-weight-bold fontSizeTitle">{t('moduleReports.label.AreaActiveMost')}</span>
                            {isDataCeroState() ? null :
                                <ExportToCsv data={ticketsStore} fileName="states_tickets.csv" />}
                        </div>
                        <div className="chart-content">
                            {isDataCeroState() ? templateNoDataReport() :
                                <Pie
                                    data={registrationArea}
                                    options={optionsLabelLeft} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reports;