import Api from '../utils/axios';
const api = new Api();

export const getStores = async () => {
    return await api.getStores()
}

export const createStore = async (data) => {
    return await api.createStore(data)
}

export const deleteStore = async (id) => {
    return await api.deleteStore(id)
}

export const updateStore = async (id, data) => {
    return await api.updateStore(id, data);
}


export const getProducts = async () => {
    return await api.getProduct()
}
export const getStoreById = async (id) => {
    return await api.getStoreById(id)
}