import React from 'react';

const Select = ({
    items,
    onChange,
    optionTitle,
    formik,
    formikFieldProps,
    value,
    messageDefault
}) => {

    return (
        <>
            <select
                className="form-control"
                onChange={e => onChange(items.find(element => element.value == e.target.value))}
                value={value}
            >
                <option value="" disabled selected >Seleccione una opción</option>
                {items && items.length > 0 ? (
                    items.map((item, i) => (
                        <option key={"category-" + i} value={item.value}>
                            {item[optionTitle]}
                        </option>
                    ))
                ) : null}
            </select>
        </>
    );
};

export default Select;
