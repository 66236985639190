export const customStyles = {
    rows: {
        style: {
            fontSize: '14px'
        },
    },
    headCells: {
        style: {
            fontSize: '16px',
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

export const customStylesTickets = {
    rows: {
        style: {
            fontSize: '14px'
        },
    },
    headCells: {
        style: {
            fontSize: '16px',
            paddingLeft: '10px', // override the cell padding for head cells
            paddingRight: '20px',
        },
    },
    cells: {
        style: {
            paddingLeft: '10px', // override the cell padding for data cells
            paddingRight: '20px',
            width: '100px'
        },
    },
};

export const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};