import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Button, FilterComponent} from '@components';
import { useHistory } from 'react-router-dom';
import * as usersService from '../../services/clients';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';

import { customStyles, paginationComponentOptions } from '../../utils/table-config'
import DataTable from 'react-data-table-component';

const headerButtons = [
    {
        text: "Agregar nuevo cliente",
        urlSlug: "/clients/create",
    }
];

const Clients = () => {
    const [t] = useTranslation();
    const [clients, setClients] = useState([])
    const history = useHistory()
    const loading = useSelector(state => state.ui.loading)
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            name: <b>{t('clients.label.UIDGigya')}</b>,
            selector: row => row.uid,
            sortable: true,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                },
            },
        },
        {
            name: <b>{t('general.label.name')}</b>,
            selector: row => row.name === null ? '-' : row.name,
            sortable: true,
        },
        {
            name: <b>{t('general.label.lastName')}</b>,
            selector: row => row.lastName === null ? '-' : row.lastName,
            sortable: true,
        },
        {
            name: <b>{t('general.label.points')}</b>,
            selector: row => row.pointValue,
            sortable: true,
        },
        {
            name: <b>{t('general.label.email')}</b>,
            selector: row => row.email === null ? '-' : row.email,
            sortable: true,
        },
        {
            name: '',
            cell: (row) => <Button className="m-1 btn btn-danger" onClick={() => handleButtonClick(row)} id={row.id} ><i className="icon-button fa fa-edit"></i></Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]);

    // Hooks para el filtro de la tabla
    const [filterEmail, setFilterEmail] = useState('');
    const [filterUid, setFilterUid] = useState('');
    const [filterName, setFilterName] = useState('');
    // Paginations Reset
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // Funcion principal para el filtro multiple
    const filteredItems = clients.filter( 
        item => (item.uid && item.uid.toLowerCase().includes(filterUid.toLowerCase())) &&
            (item.email && item.email.toLowerCase().includes(filterEmail.toLowerCase()))
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterEmail) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterEmail('');
            }

            if (filterUid) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterUid('');
            }

            if (filterName) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterName('');
            }
        };

        return (
            <div className='d-flex p-2'>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por UID' onFilter={e => setFilterUid(e.target.value)} filterText={filterUid} />
                </div>
                {/*<div className="p-2">*/}
                {/*    <FilterComponent placeholder='Filtrar por Nombre' onFilter={e => setFilterName(e.target.value)} filterText={filterName} />*/}
                {/*</div>*/}
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por Email' onFilter={e => setFilterEmail(e.target.value)} filterText={filterEmail} />
                </div>
                <div className="p-2">
                    <Button className="btn btn-danger" type="button" onClick={handleClear}>
                        Limpiar
                    </Button>
                </div>
            </div>
        );
    }, [filterEmail, filterUid, filterName, resetPaginationToggle]);


    useEffect(() => {
        dispatch(setLoading(true));
        usersService.getClients()
            .then(response => {
                setClients(response.data)
                dispatch(setLoading(false));
            })
            .catch(err => {
                dispatch(setLoading(false));
            })
    }, [])

    const handleButtonClick = (item) => {
        history.push(`/clients/edit/${item.uid}`)
    };

    return (
        <>
            <div className="container-fluid">
                <ContentHeader title={t('menusidebar.label.clients')} /*buttons={headerButtons}*/ />
                {clients && clients.length > 0 ? (
                    <DataTable
                        data={filteredItems}
                        customStyles={customStyles}
                        progressPending={loading}
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        pagination
                        noDataComponent="No se encuentran registros"
                        paginationComponentOptions={paginationComponentOptions}
                        highlightOnHover={true}
                        columns={columns}
                    />
                ) : <div>
                    <h1 className="text-center">{t('general.label.noRegisters')}</h1>
                </div>}

            </div>
        </>
    );
};

export default Clients;
