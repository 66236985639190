import React, { useState, useEffect } from 'react';
import { ComboBox, Select, Button, Input } from '@components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Api from '../../utils/axios'
import { useHistory, Link } from 'react-router-dom';
import * as usersService from '../../services/clients';

const CreateClients = () => {
    const api = new Api();
    const history = useHistory();
    const [clients, setClients] = useState(false);
    const [genderName, setGender] = useState();
    const [gender, setIdGender] = useState('');
    const [isSaveLoading, setSaveLoading] = useState(false);
    const [t] = useTranslation();

    const dataGender = [
        { id: 1, name: 'Masculino', value: 'm' },
        { id: 2, name: 'Femenino', value: 'f' }
    ]

    useEffect(() => {
        setGender(dataGender)
    }, [])

    const formik = useFormik({
        initialValues: {
            name: '',
            lastName: '',
            dateOfBirth: '',
            email: '',
            password: '',
            gender:''

        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(30, t('validationSchema.max30Caracters'))
                .required(t('validationSchema.requiredName')),
            lastName: Yup.string()
                .max(15, t('validationSchema.max30Caracters'))
                .required(t('validationSchema.requiredlastName')),
            dateOfBirth: Yup.string()
                .max(130, t('validationSchema.min130Caracters'))
                .required(t('validationSchema.requiredlastDateOfBirth')),
            email: Yup.string()
                .max(30, t('validationSchema.max30Caracters'))
                .max(150, t('validationSchema.max150Caracters'))
                .required(t('validationSchema.requiredEmailClient')),
            password: Yup.string()
                .min(8, t('validationSchema.min8Caracters'))
                .required(t('validationSchema.password'))
                .matches(
                    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                    t('validationSchema.passwordMatches'))
        }),
        onSubmit: (values) => {
            usersData(values);
        }
    });

    const usersData = async (data) => {

        data.gender = gender;
        //console.log(gender)
        //console.log(data)

        try {
            const res = await usersService.createClient(data);
            console.log(res)
            toast.success(t('general.label.successRegister'));
            history.push('/clients');
            setSaveLoading(true);
            setSaveLoading(false);
        } catch (error) {
            setSaveLoading(false);
            setClients(false);
            console.log(error.response.data.errors[0])
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }

    }


    return (
        <div>
            {/*<ContentHeader title={t('menusidebar.label.clients')} buttons={headerButtons} />*/}
            <div className="container-fluid">
                <div className="card ">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('clients.label.clientCreated')}</h3>
                    </div>
                    <div className="p-3">
                        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>{ t('general.lable.name')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('name')}
                                        type="text"
                                        placeholder={t('general.lable.name')} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>{t('general.lable.lastName')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('lastName')}
                                        type="text"
                                        placeholder={t('general.lable.lastName')} />
                                </div>
                            </div>
                            <div className="form-row">
                               
                                <div className="form-group col-md-6">
                                    <label>{t('general.lable.email')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('email')}
                                        type="email"
                                        placeholder={t('general.lable.email')} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>{t('clients.lable.password')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('password')}
                                        type="password"
                                        placeholder={t('clients.lable.password')} />
                                </div>
                            </div>
                            <div className="form-row">
                                

                                <div className="form-group col-md-6">
                                    <label>{t('clients.lable.dateOfBirth')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('dateOfBirth')}
                                        type="date"
                                        placeholder={t('clients.lable.dateOfBirth')} />
                                </div>

                                <div className="form-group col-md-6">

                                    <label
                                        htmlFor="inputName"
                                        className="col-sm-2 col-form-label"
                                    >
                                        { t('general.lable.gender')}
                                    </label>
                                    {genderName && genderName.length > 0 ? (
                                        <div className="col-sm-4">
                                            <Select
                                                items={genderName}
                                                optionTitle="name"
                                                onChange={(item) => setIdGender(item.value)}
                                                value={gender}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('gender')}  />
                                        </div>
                                    ) : null} 

                                    
                                </div>

                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/clients/`}
                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        className="w-10"
                                        isLoading={isSaveLoading}
                                        type="submit" block
                                        className="btn btn-danger" > {t('general.buttons.addClient')} </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateClients;
