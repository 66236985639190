import React, { useState } from "react";
import './ImageZoom.scss'

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

const ImageZoom = ({ src }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleShowDialog = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="image-content-zoom">
            <TransformWrapper defaultScale={1} >
                <TransformComponent>
                    <img
                        className="image"
                        src={src}
                        onClick={handleShowDialog}
                        alt="no image"
                    />
                </TransformComponent>
            </TransformWrapper>
            {isOpen && (
                <dialog
                    className="dialog"
                    style={{ position: "absolute" }}
                    open
                    onClick={handleShowDialog}
                >
                    <TransformWrapper defaultScale={1} >
                        <TransformComponent>
                            <img
                                className="image"
                                src={src}
                                onClick={handleShowDialog}
                                alt="no image"
                            />
                        </TransformComponent>
                    </TransformWrapper>
                </dialog>
            )}
        </div>
    );
}

export default ImageZoom;