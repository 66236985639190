import React, { useState, useEffect, useMemo }from "react";
import {useTransition, useTranslation} from "react-i18next";
import {Button, ContentHeader} from "@components";
import * as ticketService from '../../services/tickets';
import DataTable from "react-data-table-component";
import {customStylesTickets, paginationComponentOptions} from "@app/utils/table-config";
import {useHistory} from "react-router-dom";

const Stores = () => {
    const [t] = useTranslation();
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(true)
    const history = useHistory();
    
    const columns = useMemo(() => [
        {
            name: <b>Id</b>,
            selector: row => row.id,
            sortable: true,
        },
        {
            name: <b>Nombre</b>,
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: '',
            cell: (row) => <Button className="m-1 btn btn-danger btnEdit" onClick={() => handleEdit(row)} id={row.id} ><i className="icon-button fa fa-edit"></i></Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]);

    const handleEdit = (item) => {
        history.push(`/stores/edit/${item.id}`)
        
    };
    // buscamos los datos de state y las tiendas para los dropdows.
    useEffect(() => {
        ticketService.getStores()
            .then((response) => {
                setStores(response.data)
            }).catch(error => {
            console.log(error)
        })
    }, [])
    
    return (
        <div className="container-fluid">
            <ContentHeader title={"Tiendas"} />

            {stores && stores.length > 0 ? (
                <DataTable
                    data={stores}
                    columns={columns}
                    noDataComponent="No se encuentran registros"
                />
            ) : <div>
                <h1 className="text-center">{t('general.label.noRegisters')}</h1>
            </div>
            }
        </div>
    )
}

export default Stores;
