import React, { useEffect, useState } from 'react';
import "./ImageUpload.scss";
import { toast } from 'react-toastify';

const ImageUpload = ({
    id = 'details',
    label,
    className,
    type = 'file',
    onChange,
    image,
    uploadImage,
    witdh = 512,
    height = 512,
    hideContent = false,
    isLogo = false,
}) => {

    const checkSizeImage = (file) => {
        const img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.onload = () => {
            if (img.width === witdh && img.height === height)
                uploadImage(file)
            else
                toast.error(`Las dimensiones (${img.width}x${img.height}) de la imagen no son correctas`);

            return false
        }

    }

    const validateFile = (file) => {
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    }

    const handleFileChange = async (e) => {
        if (!window.FileReader) {
            toast.error('El navegador no soporta la lectura de archivos');
            return;
        }
        const file = e.target.files[0];
        if (validateFile(file))
            checkSizeImage(file)
    }

    return (
        <div className="image-upload">
            <div className="form-group row">
                <label
                    className="col-sm-12 col-form-label"> {label} </label>
            </div>
            {isLogo ?
                <div className="image-content-input">
                    <div className="image-content-logo">
                        {image && image.url ?
                            <img className={className} src={image.url} alt={label} />
                            : <div className="d-flex align-items-center justify-content-center">
                                <p>
                                    La imagen puede ser con los siguientes formatos
                                    con un formato <strong>.png, .jpg </strong> o <strong> .jpeg </strong>
                                    y con un tamaño de imagen de <strong>{witdh}x{height}px</strong>
                                </p>
                            </div>}
                    </div>
                </div> : null}
            {hideContent ? null :
                <div className="image-content-input">
                    <div className="image-content">
                        {image && image.url ?
                            <img className={className} src={image.url} alt={label} />
                            : <div className="d-flex align-items-center justify-content-center">
                                <p>
                                    La imagen puede ser con los siguientes formatos
                                    con un formato <strong>.png, .jpg </strong> o <strong> .jpeg </strong>
                                    y con un tamaño de imagen de <strong>{witdh}x{height}px</strong>
                                </p>
                            </div>}
                    </div>
                </div>}
            <div className="input-group">
                <div className="custom-file">
                    <input
                        type={type}
                        name="image"
                        className="custom-file-input"
                        id={id}
                        accept="image/*"
                        onChange={handleFileChange}
                        placeholder="Detalles"
                    />
                    <label className="custom-file-label" >Seleccione una imagen</label>
                </div>
                <div className="input-group-append">
                    <span className="input-group-text">Subir</span>
                </div>
            </div>
        </div>
    );
};

export default ImageUpload;