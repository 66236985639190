import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Button } from '@components';
import { useHistory } from 'react-router-dom';
import * as usersService from '../../services/donations';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';

import { customStylesTickets, paginationComponentOptions } from '../../utils/table-config'
import DataTable from 'react-data-table-component';

const headerButtons = [
    {
        text: "Agregar nueva donación",
        urlSlug: "/donations/create",
    }
];

const Donations = () => {
    const [t] = useTranslation();
    const [donations, setDonations] = useState([])
    const history = useHistory();
    const loading = useSelector(state => state.ui.loading)
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            name: <b>Id</b>,
            selector: row => row.id,
            sortable: true,
        },
        {
            name: <b>{ t('general.label.name')}</b>,
            selector: row => row.name === null ? '-' : row.name,
            sortable: true,
        },
        {
            name: <b>{t('donations.label.description')}</b>,
            selector: row => row.description === null ? '-' : row.description,
            sortable: true,
        },
        {
            name: <b>{t('donations.label.pointsGoal')}</b>,
            selector: row => row.pointsGoal === null ? '-' : row.pointsCollected + '/'+ row.pointsGoal,
            sortable: true,
        },
        {
            name: <b>{t('donations.label.percentageOfPoints')}</b>,
            selector: row => ((row.pointsCollected / row.pointsGoal) * 100).toFixed(2),
            sortable: true,
            format: row => ((row.pointsCollected / row.pointsGoal) * 100).toFixed(2) + '%',
        },
        {
            name: <b>{t('donations.label.promotorName')}</b>,
            selector: row => row.promoterName === null ? '-' : row.promoterName,
            sortable: true,
        },
        {
            name: '',
            cell: (row) => <Button className="m-1 btn btn-danger" onClick={() => handleButtonClick(row)} id={row.id} ><i className="icon-button fa fa-edit"></i></Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]);

    useEffect(() => {
        dispatch(setLoading(true));
        usersService.getDonations()
            .then(response => {
                setDonations(response)
                dispatch(setLoading(false));
            })
            .catch(err => {
                console.log(err)
                dispatch(setLoading(false));
            })

    }, [])

    const handleButtonClick = (item) => {
        history.push(`/donations/edit/${item.id}`)
    };

    return (
        <>
            <div className="news-list">
                <ContentHeader title={t('menusidebar.label.donations')} buttons={headerButtons} />

                {donations && donations.length > 0 ?
                    <DataTable
                        data={donations}
                        customStyles={customStylesTickets}
                        progressPending={loading}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        highlightOnHover={true}
                        columns={columns}
                    />
                    : <div>
                        <h1 className="text-center">{t('general.label.noRegisters')}</h1>
                    </div>}
            </div>
        </>
    );
};

export default Donations;
