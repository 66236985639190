import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Pin from './images/map-pin.png'

const containerStyle = {
    width: '100%',
    height: '100%'
}

const libraries = (process.env.REACT_APP_GOOGLE_LIBS || '').split(',');

const Gmap = ({ center, onChangeCenter }) => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        language: 'es',
        region: 'ES',
        libraries
    });

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    const options = {
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: true,
        zoom: 15,
        region: 'es'
    }

    const handleMapClick = (ref) => {
        onChangeCenter(ref.latLng.toJSON())
    };

    return isLoaded ? (
        <GoogleMap
            id="google-map-root"
            mapContainerStyle={containerStyle}
            center={center}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={options}
            zoom={15}
            onClick={handleMapClick}
        >
            <Marker
                draggable={true}
                icon={Pin}
                position={center}
                lat={center.lat}
                lng={center.lng}
                onClick={handleMapClick}
                onDragEnd={handleMapClick}
            />
        </GoogleMap>
    ) : <></>
};

export default Gmap;
