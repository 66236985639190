import React, { useState, useEffect } from 'react';
import { Button, Input, ComboBox } from '@components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, useParams, Link } from 'react-router-dom';
import * as usersService from '../../services/users';
import * as Yup from 'yup';
import Api from '../../utils/axios'

const CreateTag = () => {

    const api = new Api();
    const history = useHistory();
    const params = useParams();
    const [tags, setTags] = useState(false);
    const [t] = useTranslation();

    useEffect(() => {
    }, [])

    const formik = useFormik({
        initialValues: {
            name: '',
            createdAt: null,
            updatedAt: null

        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(30, t('validationSchema.max30Caracters'))
                .required(t('validationSchema.categoryNameRequired'))
        }),
        onSubmit: (values) => {
            usersData(values);
        }
    });

    const usersData = async (data) => {

        try {
            const res = await api.createTag(data);
            setTags(true);
            setTags(false);
            toast.success(t('general.label.successRegister'));
            history.push('/news/categories');
        } catch (error) {
            setTags(false);
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }

    }

    return (
        <div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('news.label.createTag')}</h3>
                    </div>
                    <div className="p-3">
                        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>{t('news.label.nameTag')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('name')}
                                        type="text"
                                        placeholder={t('news.label.nameTag')} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">

                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('createdAt')}
                                        type="hidden"
                                        step="1" min="2022-01-01T00:00Z" value="2022-02-15T12:00"
                                        placeholder="Fecha de nacimiento"
                                        disabled />
                                </div>
                                <div className="form-group col-md-6">
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('updatedAt')}
                                        type="hidden"
                                        step="1" min="2022-01-01T00:00Z" value="2022-02-15T12:00"
                                        placeholder="Fecha de actualizacion"
                                        disabled />
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/news/categories`}
                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        isLoading={tags}
                                        type="submit" block
                                        className="btn btn-danger" > {t('news.label.addCategory')} </Button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateTag;
