import React, { useState, useEffect } from 'react';
import { Button, Input, Select, Checkbox } from '@components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, Link } from 'react-router-dom';
import * as usersService from '../../services/users';
import * as Yup from 'yup';

const roles = [
    {
        id: '1',
        name: 'Admin'
    },
    {
        id: '2',
        name: 'SuperAdmin'
    }
]

const Create = () => {
    const history = useHistory();
    const [users, setUsers] = useState(false);
    const [role, setRole] = useState('admin');
    const [hasSuperAdmin, setHasSuperAdmin] = useState(false)
    const [t] = useTranslation();

    const onChange = (event, { newValue }) => {
        setInputValue(newValue.replace(/\s/g, ''));
    };

    const formik = useFormik({
        initialValues: {
            userName: '',
            password: '',
            passwordConfirmation: '',
            email: '',
            name: '',
            lastName: '',
            secondLastName: '',
            dateOfBirth: '',
        },
        validationSchema: Yup.object({
            userName: Yup.string()
                .min(5, t('validationSchema.min5Caracters'))
                .matches(/^(\S+$)/g, t('validationSchema.noBlankSpaces'))
                .required(t('validationSchema.userName')),
            password: Yup.string()
                .required(t('validationSchema.password'))
                .min(8, t('validationSchema.min8Caracters'))
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    t('validationSchema.passwordMatches')
                ),
            passwordConfirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], t('validationSchema.passwordConfirm'))
                .required(t('validationSchema.passwordConfirmRequired'))
                .min(8, t('validationSchema.min8Caracters')),
            email: Yup.string()
                .email(t('validationSchema.emailValidate'))
                .required(t('validationSchema.requiredEmail')),
            name: Yup.string().required(t('validationSchema.requiredUserName')),
            lastName: Yup.string().required(t('validationSchema.requiredUserLastName')),
            secondLastName: Yup.string().required(t('validationSchema.requiredUserMotherLastName'))
        }),
        onSubmit: (values) => {
            usersData(values);
        }
    });

    const usersData = async (data) => {
        const user = { ...data, rolesNames: hasSuperAdmin ? ['Admin', 'SuperAdmin'] : ['Admin'] }
        try {
            const res = await usersService.createUser(user);
            console.log(res)
            console.log(res.data.details[0].message)
            var respnseMessage = res.data.details[0].message;
            toast.success(respnseMessage);
            history.push('/users');
        } catch (error) {
            console.log(error.response.data.errors[0])
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }
    }

    return (
        <div>
            {/*<ContentHeader title={t('menusidebar.label.users')} buttons={headerButtons} />*/}
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header p2">
                        <h3 className="text-danger font-weight-bold">{t('admins.label.createNewAdmin')}</h3>
                    </div>
                    <div className="p-3">
                        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>{ t('admins.label.userName')}</label>
                                    <Input

                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('userName')}
                                        type="text"
                                        placeholder={t('admins.label.userName')} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>{t('admins.label.password')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('password')}
                                        type="password"
                                        placeholder={t('admins.label.password')} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>{t('general.label.email')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('email')}
                                        type="email"
                                        placeholder={t('general.label.email')} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>{t('admins.label.confirmPassword')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('passwordConfirmation')}
                                        type="password"
                                        placeholder={t('admins.label.confirmPassword')} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label>{t('general.label.name')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('name')}
                                        type="text"
                                        placeholder={t('general.label.name')} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label>{t('admins.label.lastName')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('lastName')}
                                        type="text"
                                        placeholder={t('admins.label.lastName')} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label>{t('admins.label.motherLastName')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('secondLastName')}
                                        type="text"
                                        placeholder={t('admins.label.motherLastName')} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    {/*<label>Fecha de nacimiento</label>*/}
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('dateOfBirth')}
                                        type="hidden"
                                        step="1" min="2022-01-01T00:00Z" value="2022-02-15T12:00"
                                        placeholder="Fecha de nacimiento" />
                                </div>

                            </div>
                            <div className="form-row">
                                {roles && roles.length > 0 ? (
                                    <div className="form-group col-sm-3">
                                        <label>{ t('admins.label.rol')}</label>
                                        <Checkbox
                                            checked={hasSuperAdmin}
                                            onChange={setHasSuperAdmin}
                                            label={t('admins.label.hasSuperAdmin')}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/users/`}
                                        className="btn text-danger" > { t('general.buttons.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        isLoading={users}
                                        type="submit" block
                                        className="btn btn-danger" > {t('general.buttons.addAdmin')} </Button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
