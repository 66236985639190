import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterComponent, ContentHeader, Button } from '@components';
import { useParams, useHistory, Link } from 'react-router-dom';
import * as usersService from '../../services/tickets';
import Spinner from 'react-bootstrap/Spinner'
import Api from '../../utils/axios'
import { customStyles, paginationComponentOptions } from '../../utils/table-config'
import priceTotal from '../../utils/ticket-total'
import { prettyPrice } from '../../utils/string-manipulation'
import DataTable from 'react-data-table-component';
import prettyDate, { prettyOnlyDate } from '../../utils/pretty-date'

const Points = () => {
    const [t] = useTranslation();
    const [points, setPoints] = useState([])
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const conditionalRow = [
        {
            when: row => row.status != 0,
            style: {
                display: 'none',
            },
        },
    ];

    const columns = useMemo(() => [
        {
            name: <b>{t('tickets.label.IdTicket')}</b>,
            selector: row => row.id,
            sortable: true,
        },
        {
            name: <b>{t('tickets.label.statusTicket')}</b>,
            selector: row => row.status === 0 ? 'Aceptada' : null || row.status === 1 ? 'En proceso' : null,
            sortable: true,
        },
        {
            name: <b>{t('tickets.label.priceTicket')}</b>,
            selector: row => row.products === null ? '-' : prettyPrice(priceTotal(row.products)),
            sortable: true,
        },
        {
            name: <b>{t('tickets.label.pointsTicket')}</b>,
            selector: row => row.value === null ? '-' : row.value,
            sortable: true,
        },
        {
            name: <b>{t('tickets.label.shopName')}</b>,
            selector: row => row.cadena === null ? '-' : row.cadena.nombre,
            sortable: true,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                },
            },
        },
        {
            name: <b>{t('tickets.label.dateOfPurchase')}</b>,
            selector: row => row.dateOfPurchase === null ? '-' : prettyOnlyDate(row.dateOfPurchase),
            sortable: true,
        },
        {
            name: <b>Código</b>,
            selector: row => row.promotion === null ? '-' : row.promotion.code,
            sortable: true,
        },
        {
            name: <b>{t('GUID')}</b>,
            selector: row => row.barcode === null ? '-' : row.client.uid,
            sortable: true,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                },
            },
        },
        {
            name: <b>{t('tickets.label.nameClient')}</b>,
            selector: row => row.client.name === null ? '-' : row.client.name + ' ' + row.client.lastName,
            sortable: true,
        },
        {
            name: <b>{t('Email')}</b>,
            selector: row => row.barcode === null ? '-' : row.client.email,
            sortable: true,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                },
            },
        },
        {
            name: <b>{t('Ver ticket')}</b>,
            cell: (row) => <a target="_blank" href={ row.image.url }><Button className="btn btn-danger btnEdit"><i className="icon-button fa fa-eye"></i></Button></a>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]);

    // Hooks para el filtro de la tabla
    const [filterNameStore, setFilterNameStore] = useState('');
    const [filterClientName, setFilterClientName] = useState('');
    const [filterEmail, setFilterEmail] = useState('');
    const [filterUid, setFilterUid] = useState('');
    // Paginations Reset
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // Funcion principal para el filtro multiple
    const filteredItems = points.filter(
        item => (item.cadena.nombre && item.cadena.nombre.toLowerCase().includes(filterNameStore.toLowerCase())) &&
            (item.client.uid && item.client.uid.toLowerCase().includes(filterUid.toLowerCase())) &&
            (item.client.email && item.client.email.toLowerCase().includes(filterEmail.toLowerCase()))
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterClientName) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterClientName('');
            }

            if (filterNameStore) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterNameStore('');
            }

            if (filterEmail) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterEmail('');
            }

            if (filterUid) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterUid('');
            }
        };

        return (
            <div className='d-flex p-2'>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por Tienda' onFilter={e => setFilterNameStore(e.target.value)} filterText={filterNameStore} />
                </div>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por GUID' onFilter={e => setFilterUid(e.target.value)} filterText={filterUid} />
                </div>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por Email' onFilter={e => setFilterEmail(e.target.value)} filterText={filterEmail} />
                </div>
                <div className="p-2">
                    <Button className="btn btn-danger" type="button" onClick={handleClear}>
                        Limpiar
                    </Button>
                </div>
            </div>
        );
    }, [filterEmail, filterUid, filterNameStore, filterClientName, resetPaginationToggle]);


    useEffect(() => {
        if (loading) {
            usersService.getTickets(0)
                .then(response => {
                    setPoints(response.data)
                    setLoading(false)
                    setIsLoading(true)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }
    }, [])

    return (
        <div className="container-fluid">
            <ContentHeader title={t('menusidebar.label.pointsClient')} /*buttons={headerButtons}*/ />

            {points && points.length > 0 ? (
                
                <DataTable
                    data={filteredItems}
                    customStyles={customStyles}
                    progressPending={loading}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    noDataComponent="No se encuentran registros"
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    highlightOnHover={true}
                    conditionalRowStyles={conditionalRow}
                    columns={columns}
                />
            ) : <div>
                    <h1 className="text-center">{t('general.label.noRegisters')}</h1>
            </div>}

            <div className="mx-auto text-center mt-5">
                {isLoading ? points && points.length > 0 : <Spinner animation="border" />}
            </div>
        </div>
    );
};

export default Points;
