import React, { Suspense, lazy} from 'react';
import classes from './PageLoading.module.scss';
import './PageLoading.css'
import animationData from '../../animation/animation.json'
import Lottie from 'react-lottie'


const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


const PageLoading = () => {
    return (
        <>
            <div id="lottie-wrapper">
                <Lottie
                    id="lottie-animation"
                    className="lottie-animation"
                    options={lottieOptions}
                />
            </div>

                
        </>
    );
};

export default PageLoading;
