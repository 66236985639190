import axios from 'axios';
import store from '@store/store';
import {logoutUser} from '@store/reducers/auth';

const apiUrl = process.env.REACT_APP_API_URL;

export default class Api {
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = apiUrl;
    }

    unauthorizedRequestHandler = (error) => {
        return Promise.reject(error);
    };

    init = (willRedirect = true) => {
        let headers = {
            Accept: '*/*'
        };

        const { token } = store.getState().auth;

        if (token) {
            this.api_token = token
        }

        if (this.api_token) {
            headers.Authorization = `Bearer ${this.api_token}`;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            headers: headers
        });

        if (willRedirect) {
            this.client.interceptors.response.use(
                (response) => response,
                (error) => {
                    if (error.response.data.status === 401) {
                        store.dispatch(logoutUser());
                    }
                    return Promise.reject(error);
                }
            );
        }

        return this.client;
    };

    // app data
    getAppData = async () => this.init().get('cms/app-data/about');
    updateAppData = async (data) => this.init().put('cms/app-data/about', data);
    updateAccumulation = async (accumulationLimit) => this.init().put(`cms/app-data/accumulation-limit?accumulationLimit=${accumulationLimit}`);

    // auth
    login = async (data) => this.init().post('/auth/login', data);

    //states
    getState = async () => this.init().get(`/Address/estados`);
    getStateById = async (id) => this.init().get(`/Address/estados/${id}`);

    // users
    getUsers = async () => this.init().get(`/users`);
    getUserById = async (id) => this.init().get(`/users/${id}`);
    createUser = async (data) => this.init().post(`/users`, data);
    updateUser = async (id, data) => this.init().put(`/users/${id}`, data);
    deleteUser = async (id) => this.init().delete(`/users/${id}`);

    // clients
    getClients = async () => this.init().get(`/clients`);
    getClientById = async (uid) => this.init().get(`/clients/${uid}`);
    createClient = async (data) => this.init().post(`/clients`, data);
    addPoints = async (uid, value) => this.init().post(`/clients/${uid}/Add-Points?value=${value}`);
    updateClient = async (uid, data) => this.init().put(`/clients/${uid}`, data);
    deleteClient = async (uid) => this.init().delete(`/clients/${uid}`);

    // news 
    getNews = async () => this.init().get(`/cms/news`);
    getNewsById = async (id) => this.init().get(`/cms/news/${id}`);
    createNews = async (data) => this.init().post(`/cms/news`, data);
    updateNews = async (id, data) => this.init().put(`/cms/news/${id}`, data);
    deleteNews = async (id) => this.init().delete(`/cms/news/${id}`);

    getTags = async () => this.init().get(`/cms/news/tags`);
    getTagById = async (id) => this.init().get(`/cms/news/tags/${id}`);
    createTag = async (data) => this.init().post(`/cms/news/tags`, data);
    deleteTag = async (id) => this.init().delete(`/cms/news/tags/${id}`);
    updateTags = async (id, data) => this.init().put(`/cms/news/tags/${id}`, data);

    // tickets
    getTicketsNotValid = async (status) => this.init().get(`/cms/tickets/not-valid`);
    getTickets = async (status) => this.init().get(`/cms/tickets?status=${status}`);
    getProduct = async (idStore) => {
        if (idStore !== undefined)
            return this.init().get(`/cms/products/all/store/${idStore}`);
        else {
            return this.init().get(`/cms/products`);
        }
    }
    getTicketById = async (id) => this.init().get(`/cms/tickets/${id}`);
    addProductTicket = async (id, data) => this.init().post(`/cms/tickets/${id}/product`, data);
    deleteTicketProduct = async (idTicket, idProductTicket) => this.init().delete(`/cms/tickets/${idTicket}/product-ticket/${idProductTicket}`);
    updateTicket = async (id, data) => this.init().put(`/cms/tickets/${id}`, data);
    updateTicketByIdProduct = async (id, data) => this.init().put(`/cms/tickets/${id}/products`, data);
    patchTicket = async (id, newStatus, uid) => this.init().patch(`/cms/tickets/Change-Status/${id}?newStatus=${newStatus}&uid=${uid}`);

    // stores
    getStores = async () => this.init().get(`/cms/stores`);
    getStoreById = async (id) => this.init().get(`/cms/stores/${id}`);
    createStore = async (data) => this.init().post(`/cms/stores`, data);
    updateStore = async (id, data) => this.init().put(`/cms/stores/${id}`, data);
    deleteStore = async (id) => this.init().delete(`/cms/stores/${id}`);

    // donations
    getDonations = async () => this.init().get(`/cms/donations`);
    getDonationsById = async (id) => this.init().get(`/cms/donations/${id}`);
    createDonation = async (data) => this.init().post(`/cms/donations`, data);
    deleteDonations = async (id) => this.init().delete(`/cms/donations/${id}`);
    updateDonations = async (id, data) => this.init().put(`/cms/donations/${id}`, data);

    // rewords
    getCategories = async () => this.init().get(`/cms/benefits/categories`);
    getSubCategories = async () => this.init().get(`/cms/benefits/categories/2/subcategories`);
    getRewords = async () => this.init().get(`/cms/benefits`);
    getRewordsById = async (id) => this.init().get(`/cms/benefits/${id}`);
    createReword = async (data) => this.init().post(`/cms/benefits`, data);
    deleteReword = async (id) => this.init().delete(`/cms/benefits/${id}`);
    updateRewords = async (id, data) => this.init().put(`/cms/benefits/${id}`, data);
    getRewordsRedeem = async () => this.init().get(`/cms/benefits/redeem`);
    getRewardsRedeemDigitals = async () => this.init().get(`/cms/benefits/redeem/digitals`);
    getByIdClientIdRedeem = async (idUser, idRedeem) => this.init().get(`/cms/benefits/${idUser}/redeem/${idRedeem}`);
    patchRewords = async (idUser, idRedeem, data) => this.init().patch(`/cms/benefits/${idUser}/redeem/${idRedeem}`, data);

    // report
    getReport = async (startDate, endDate) => this.init().get(`/reports/tickets/rejected?startDate=${startDate}&endDate=${endDate}`);

    // notifications
    getNotifications = async () => this.init().get(`/cms/notifications`);
    getNotificationById = async (id) => this.init().get(`/cms/notifications/${id}`);
    createNotifications = async (data) => this.init().post(`/cms/notifications`, data);
    deleteNotification = async (id) => this.init().delete(`/cms/notifications/${id}`);
    updateNotification = async (id, data) => this.init().put(`/cms/notifications/${id}`, data);
    getNotificationClientsById = async (id) => this.init().get(`/cms/notifications/clients/${id}`);
    resendNotification = async (data) => this.init().post(`/cms/notifications/resend/${id}`);

    // files
    uploadFile = async (data) => this.init().post(`file/upload`, data)
    getFile = async (id) => this.init().get(`files/${id}`)
    getFiles = async () => this.init().get(`files`)
    deleteFile = async (id) => this.init().delete(`files/${id}`)

    // reports
    filterUsers = async () => this.init().get(`/reports/users`)
    filterStore = async (startDate, endDate) => this.init().post(`/reports/tickets/state?startDate=${startDate}&endDate=${endDate}`)
    ticketsStore = async (startDate, endDate) => this.init().post(`/reports/tickets/store?startDate=${startDate}&endDate=${endDate}`)
    filterRejected = async (startDate, endDate) => this.init().post(`/reports/tickets/rejected?startDate=${startDate}&endDate=${endDate}`)
    filterSeen = async (startDate, endDate) => this.init().post(`/reports/notifications/seen?startDate=${startDate}&endDate=${endDate}`)
    filterPoints = async (startDate, endDate) => this.init().get(`/reports/users/points?startDate=${startDate}&endDate=${endDate}`)
    filterSku = async (startDate, endDate) => this.init().post(`/reports/tickets/sku?startDate=${startDate}&endDate=${endDate}`)
    filterBabies = async () => this.init().get(`/reports/users/babies`)
    getPromotionInformation = async (type) => this.init().get(`/reports/promotions?type=${type}`);
    getPromotionReports = async (type) => this.init().get(`/reports/redeemed-promotions?type=${type}`);

    // promotions
    getPromotions = async () => this.init().get(`/cms/promotions`);
    getPromotionById = async (id) => this.init().get(`/cms/promotions/${id}`);
    createPromotion = async (data) => this.init().post(`/cms/promotions`, data);
    updatePromotion = async (id, data) => this.init().put(`/cms/promotions/${id}`, data);
    deletePromotion = async (id) => this.init().delete(`/cms/promotions/${id}`);
}