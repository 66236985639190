import React, { useState, useEffect } from 'react';
import { ContentHeader, Button, Input, ComboBox, Textarea } from '@components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Api from '../../utils/axios'
import { useHistory } from 'react-router-dom';
import * as usersService from '../../services/notifications';

const headerButtons = [];

const EditNotification = () => {
    const history = useHistory();
    const [users, setUsers] = useState(false);
    const [status, setStatus] = useState(false)
    const [t] = useTranslation();
    const { id } = useParams()
    const [user, setUser] = useState();
    const formik = useFormik({
        initialValues: {
            title: '',
            message: '',
            notificationTypeId: 0,
            field: 0,
            send: true,
            sendAll: true,
            clientId: "",

        },
        validationSchema: Yup.object({
            title: Yup.string()
                .max(30, 'Debe tener 5 caracteres o más')
                .required('Required'),
            notificationTypeId: Yup.number()
                .required('Required'),
            message: Yup.string()
                .max(30, 'Debe tener 30 caracteres o más')
                .max(150, 'Debe tener 150 caracteres o menos')
                .required('Required'),
            clientId: Yup.string()
                .required('Required'),
            subCategoryId: Yup.string()
                .required('Required'),
        }),
        onSubmit: (values) => {
            sendDataReword(values);
        }
    });

    useEffect(() => {
        usersService.getNotificationById(id)
            .then((response) => {
                formik.setValues({ ...response.data })
                setUser(response.data)
            }).catch(error => {
                console.log(error)
            })
    }, [])

    const usersData = async (data) => {
        //data.status = status;
        try {
            const res = await usersService.getNotificationById(id, data);
            console.log(res)
            toast.success("Registro editado");
            history.push('/users');
        } catch (error) {
            setUsers(false);
            toast.error(error.message || 'Failed');
        }

    }

    const handleDeleteUser = async () => {
        try {
            const respuesta = confirm("¿Estas seguro de eliminar el registro?")
            if (respuesta === true) {
                const data = await usersService.getNotificationById(id)
                if (data.successful)
                    toast.success('Admin eliminado correctamente!');

                history.push('/users');
            } else {
                return false;
            }

        } catch (err) {
            console.log(err)
        }
    }



    return (
        <div>
            {/*<ContentHeader title={t('users.label.edit')} buttons={headerButtons} />*/}
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="card-title" >Editar notificación</h3>
                        <div class="mr-1 card-tools">
                            <Button
                                isLoading={users}
                                type="submit" block
                                onClick={handleDeleteUser}
                                className="btn btn-danger"><i className="fa fa-trash"></i></Button>
                        </div>
                    </div>
                    <div className="p-3">
                        {user ? <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="form-group row">
                                        <label>Nombre de la recompensa</label>
                                        <Input
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('title')}
                                            type="text"
                                            placeholder="Nombre de la notificación" />
                                    </div>
                                    <div className="form-group row">
                                        <label>Valor / Puntos</label>
                                        <Input
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('value')}
                                            type="number"
                                            placeholder="Valor / Puntos" />
                                    </div>
                                    <div className="form-group row">
                                        <label>Mensaje</label>
                                        <Textarea
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('message')}
                                            type="text"
                                            placeholder="Mensaje" />
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Tipo de notificación
                                        </label>
                                        {categories && categories.length > 0 ? (
                                            <div className="col-sm-3">
                                                <ComboBox
                                                    items={categories}
                                                    optionTitle="name"
                                                    onChange={(item) => setCategoryId(item.id)}
                                                    formik={formik}
                                                    formikFieldProps={formik.getFieldProps('category')}
                                                    placeholder="Categoria" />
                                            </div>
                                        ) : null}
                                    </div>



                                </div>

                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <a href="/rewards" className="text-danger"> Cancelar </a>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        className="w-10"
                                        isLoading={isSaveLoading}
                                        type="submit"
                                        className="btn btn-primary" > Crear recompensa </Button>
                                </div>
                            </div>
                        </form> : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditNotification;
