const removeLineBreaks = string => {
    return string.replace(/\r?\n|\r/, '')
}

const prettyPrice = value => {    
    value = Number(value).toLocaleString()
    if(!value.includes('.'))
        value += '.00'
    else {
        const split = value.split('.')
        const digits = split[0]
        let decimals = split[1]
        if(decimals.length === 1)
            decimals += '0'
        value = digits + '.' + decimals
    }
    return '$' + value + ' MXN'
}

const prettyStatusNews = value => {
    if (!value)
        return 'Inactiva'

    return 'Activa'
}

export { removeLineBreaks, prettyPrice, prettyStatusNews}