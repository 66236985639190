import React from 'react';

const ComboBox = ({
    items,
    onChange,
    optionTitle,
    formik,
    formikFieldProps,
    value,
    hiddenOptionDefault = true,
    messageOptionDefault = 'Seleccione una opción'
}) => {
    
    return (
        <>
            <select
                className="form-control"
                onChange={e => onChange(items.find(element => element.id == Number(e.target.value)))}
                value={value}
                autoComplete="on"
            >
                <option value={null} hidden={hiddenOptionDefault}>{messageOptionDefault}</option>
                {items && items.length > 0 ? (
                    items.map((item, i) => (
                        <option key={"category-" + i} value={item.id}>
                            {item[optionTitle]}
                        </option>
                    ))
                ) : null}
            </select>
        </>
    );
};

export default ComboBox;
