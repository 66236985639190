import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { loginUser } from '@store/reducers/auth';
import { Checkbox, Button, Input } from '@components';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from "react-google-recaptcha";

import * as Yup from 'yup';

import * as AuthService from '../../services/auth';

const Login = () => {
    const [isAuthLoading, setAuthLoading] = useState(false);
    const dispatch = useDispatch();
    const captchaRef = useRef(null);

    const history = useHistory();
    const [t] = useTranslation();

    const login = async (username, password) => {
        const token = captchaRef.current.getValue();
        
        if (token === "") {
            toast.error(t('login.label.errorRecaptcha'));
            return;
        }
        
        try {
            setAuthLoading(true);
            const token = await AuthService.loginByAuth({ username: username, password });
            //console.log(token)
            toast.success(t('login.label.sessionCorrect'));
            dispatch(loginUser(token));
            history.push('/');
        } catch (err) {
            setAuthLoading(false);
            const statusCode = err.response.status

            if (statusCode === 404) {
                toast.error(t('login.label.errorSignIn'));
            } else {
                console.log(err.response.data.errors[0])
                const errorMessage = err.response.data.errors[0]
                toast.error(errorMessage);

            }
        }
    };

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .max(30, t('validationSchema.max30Caracters'))
                .required(t('validationSchema.fieldRequired')),
            password: Yup.string()
                .min(5, t('validationSchema.fieldRequired'))
                .max(30, t('validationSchema.max30Caracters'))
                .required(t('validationSchema.fieldRequired'))
        }),
        onSubmit: (values) => {
            login(values.username, values.password);
        }
    });

    document.getElementById('root').classList = 'hold-transition login-page';

    return (
        <div className="login-box">
            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                    <img
                        src="./.../../img/huggiesnegativo.png"
                        alt="Huggies Logo"
                        width="70%"
                        className="rounded"
                        style={{ opacity: '.8', marginLeft: '11px' }}
                    />
                </div>
                <div className="card-body">
                    <h4 className="login-box-msg">{t('login.label.signIn')}</h4>
                    <form onSubmit={formik.handleSubmit}>
                        
                        <div className="mb-3">
                            <Input
                                icon={faEnvelope}
                                placeholder="Nombre de Usuario"
                                type="text"
                                formik={formik}
                                formikFieldProps={formik.getFieldProps('username')}
                            />
                        </div>
                        <div className="mb-3">
                            <Input
                                icon={faLock}
                                placeholder={t('login.label.password')}
                                type="password"
                                formik={formik}
                                formikFieldProps={formik.getFieldProps(
                                    'password'
                                )}
                            />
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <Checkbox
                                    checked={false}
                                    label={t('login.label.rememberMe')}
                                />
                            </div>
                            <div className="col-6">
                                <Button
                                    block
                                    type="submit"
                                    isLoading={isAuthLoading}

                                >
                                    {t('login.button.signIn.label')}
                                </Button>
                            </div>
                        </div>

                        <ReCAPTCHA
                            ref={captchaRef}
                            sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY} />
                        
                        {/*div className="row">
                            <div className="col-8 pt-3 pb-2">
                                <a href="/recover-password" className="text-danger"> {t('login.label.recoverPassword')} </a>
                            </div>
                        </div>*/}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
