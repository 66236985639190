import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Button } from '@components';
import { useHistory } from 'react-router-dom';
import * as usersService from '../../services/users';

import { customStyles, paginationComponentOptions } from '../../utils/table-config'
import DataTable from 'react-data-table-component';

const headerButtons = [
    {
        text: "Agregar nuevo administrador",
        urlSlug: "/users/create",
    }
];

const Users = () => {
    const [t] = useTranslation();
    const [users, setUsers] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()
    const dispatch = useDispatch();
    const getRol = JSON.parse(localStorage.getItem('datauser'))
    const idUser = getRol.id;
    const columns = useMemo(() => [
        {
            name: <b>Id</b>,
            selector: row => row.id,
            sortable: true,
        },
        {
            name: <b>{ t('admins.label.userName')}</b>,
            selector: row => row.userName === null ? '-' : row.userName,
            sortable: true,
        },
        {
            name: <b>{t('general.label.email')}</b>,
            selector: row => row.email === null ? '-' : row.email,
            sortable: true,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                },
            },
        },
        {
            name: <b>{t('general.label.name')}</b>,
            selector: row => row.name === null ? '-' : row.name,
            sortable: true,
        },
        {
            name: <b>{t('general.label.lastName')}</b>,
            selector: row => row.lastName === null ? '-' : row.lastName,
            sortable: true,
        },
        {
            name: <b>{t('admins.label.motherLastName')}</b>,
            selector: row => row.secondLastName === null ? '-' : row.secondLastName,
            sortable: true,
        },
        {
            name: '',
            cell: (row) => idUser === row.id ? <Button className="m-1 btn btn-danger btnEdit" disabled onClick={() => handleButtonClick(row)} id={row.id} ><i className="icon-button fa fa-edit"></i></Button> : <Button className="m-1 btn btn-danger btnEdit" onClick={() => handleButtonClick(row)} id={row.id} ><i className="icon-button fa fa-edit"></i></Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]);

    useEffect(() => {
        dispatch(setLoading(true));
        if (isLoading) {
            setIsLoading(false)
            usersService.getUsers()
                .then(response => {
                    setUsers(response)
                    dispatch(setLoading(false));
                })
                .catch(err => {
                    dispatch(setLoading(false));
                })
            
        }
    }, [])


    const handleButtonClick = (item) => {
            history.push(`/users/edit/${item.id}`)
    };


    return (
        <>
            <div className="container-fluid">
                <ContentHeader title={t('menusidebar.label.users')} buttons={headerButtons} />

                { users && users.length > 0 ? (
                    <DataTable
                        data={users}
                        customStyles={customStyles}
                        progressPending={isLoading}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        highlightOnHover={true}
                        columns={columns}
                    />
                ) : <div>
                        <h1 className="text-center">{t('general.label.noRegisters')}</h1>
                </div>}

            </div>
        </>
    );
};

export default Users;
