import React, { useState, useEffect } from 'react';
import { ContentHeader, Button, Input, ComboBox } from '@components';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Api from '../../utils/axios'
import * as ticketService from '../../services/tickets';



const CreateTicket = () => {

    const api = new Api();
    const history = useHistory();
    const [isSaveLoading, setSaveLoading] = useState(false);
    const [product, setProduct] = useState();
    const [productId, setIdProduct] = useState(1);
    const [t] = useTranslation();
    const { id } = useParams()

     useEffect(() => {
         api
             .getProduct()
             .then((response) => {
                 setProduct(response.data)
                 console.log(response.data)
             }).catch(error => {
                 console.log(error)
             })
    }, [])

    const formik = useFormik({
        initialValues: {
            productId: 0,
            price: 0,
            quantity: 0,
            ticketId: 0

        },
        validationSchema: Yup.object({
            productId: Yup.number()
                .required('Required'),
            price: Yup.number()
                .required('Required'),
            quantity: Yup.number()
                .required('Required'),
            ticketId: Yup.number()
                .required('Required')
            
        }),
        onSubmit: (values) => {
            addProductTicketData(values);
        }
    });

    console.log(productId)

    const addProductTicketData = async (data) => {
        console.log(productId)
        data.productId = productId
        try {
            const res = await ticketService.addProductTicket(id, data);
            console.log(res)
            toast.success("Producto agregado correctamente");
            history.push('/ticketScan');
            setSaveLoading(true);
            setSaveLoading(false);
        } catch (error) {
            setSaveLoading(false);
            toast.error('Error al agregar el producto');
        }
    }


    return (
        <div>
            {/*<ContentHeader title={t('menusidebar.label.clients')} buttons={headerButtons} />*/}
            <div className="container-fluid">
                <div className="card ">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">Agregar productos al ticket</h3>
                    </div>
                    <div className="p-3">
                        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-5">
                                    <label
                                        htmlFor="inputName"
                                        className="col-sm-2 col-form-label"
                                    >
                                        Producto
                                    </label>
                                    {product && product.length > 0 ? (
                                        <div className="col-sm-4">
                                            <ComboBox
                                                items={product}
                                                optionTitle="canal"
                                                messageDefault="Seleccione un producto"
                                                onChange={(item) => setIdProduct(item.id)}
                                                value={productId}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('productId')} />
                                        </div>
                                    ) : <strong>No hay productos para seleccionar</strong>}
                                    {/*<label>Producto</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('productId')}
                                        type="number"
                                        placeholder="Producto" />*/}
                                    
                                </div>
                                <div className="form-group col-md-2">
                                    <label>Precio</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('price')}
                                        type="number"
                                        placeholder="Precio" />
                                </div>
                            </div>
                            <div className="form-row">

                                <div className="form-group col-md-5">
                                    <label>Cantidad</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('quantity')}
                                        type="number"
                                        placeholder="Cantidad" />
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Id del ticket</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('ticketId')}
                                        type="number"
                                        placeholder="Ticket" />
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/ticketScan/`}
                                        className="btn text-danger" > Cancelar </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        className="w-10"
                                        isLoading={isSaveLoading}
                                        type="submit" block
                                        className="btn btn-danger" > Agregar producto</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateTicket;
