import Api from '../utils/axios';
const api = new Api();

export const getTicketsListNotValid = async () => {
    const ticketsNoValid = await api.getTicketsNotValid()
    return ticketsNoValid;
}

export const getTickets = async (status = -1) => {
    const ticket = await api.getTickets(status)
    return ticket;
}

export const getProduct = async () => {
    const ticket = await api.getProduct()
    return ticket;
}

export const addProductTicket = async (id, data) => {
    const ticketCreated = await api.addProductTicket(id, data)
    return ticketCreated;
}

export const deleteTicketProduct = async (idTicket, idProductTicket) => {
    const deleteTick = await api.deleteTicketProduct(idTicket, idProductTicket);
    return deleteTick;
}

export const updateTicket = async (id, data) => {
    const updateTickeet = await api.updateTicket(id, data);
    return updateTickeet;
}

export const updateTicketByIdProduct = async (id, data) => {
    const response = await api.updateTicketByIdProduct(id, data);
    return response;
}

export const getTicketById = async (id) => {
    const foundTicket = await api.getTicketById(id);
    return foundTicket
}

export const patchTicket = async (id, newStatus, uid) => {
    const response = await api.patchTicket(id, newStatus, uid);
    return response
}

export const getStores = async () => {
    const stores = await api.getStores();
    return stores;
}