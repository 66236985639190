import React, { useState, useEffect } from 'react';
import { Button, Input, ImageUpload, Textarea, Gmap } from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, useParams, Link } from 'react-router-dom';
import * as usersService from '../../services/donations';
import * as Yup from 'yup';
import Api from '../../utils/axios'
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/

const centerGoe = {
    lat: 19.427136953672207,
    lng: -99.1675725286645
};

const EditDonation = () => {
    const { id } = useParams()
    const api = new Api();
    const [t] = useTranslation();
    const history = useHistory();
    const [donations, setDonations] = useState(false);
    const [donation, setDonation] = useState(false);
    const [isSaveLoading, setSaveLoading] = useState(false);
    const [address, setAddress] = useState("");
    const [uploadImage, setUploadImage] = useState();
    const [uploadLogo, setUploadLogo] = useState();
    const dispatch = useDispatch();

    const [center, setCenter] = useState({
        lat: centerGoe.lat,
        lng: centerGoe.lng
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            coverImageId: null,
            coverImage: null,
            coverLogoId: null,
            coverLogo: null,
            description: '',
            pointsGoal: 0,
            promoterName: '',
            phone: '',
            address: {
                id: 0,
                street: '',
                postalCode: '',
                externalNumber: '',
                latitude: 0,
                longitude: 0,
                internalNumber: '',
                city: '',
                country: '',
                state: '',
                hometown: '',
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .max(30, t('validationSchema.max30Caracters'))
                .required(t('validationSchema.nameDinbationRequired')),
            description: Yup.string()
                .max(30, t('validationSchema.max30Caracters'))
                .max(150, t('validationSchema.max150Caracters'))
                .required(t('validationSchema.descriptionName')),
            pointsGoal: Yup.number()
                .required(t('validationSchema.pointGoal')),
            promoterName: Yup.string()
                .max(150, t('validationSchema.max150Caracters'))
                .required(t('validationSchema.promotorName')),
            phone: Yup.string()
                .required(t('validationSchema.phone'))
                .matches(phoneRegExp, t('validationSchema.formartInvalid')),
            address: Yup.object().shape({
                street: Yup.string()
                    .required(t('validationSchema.street')),
                externalNumber: Yup.number()
                    .required(t('validationSchema.externalNumber')),
                postalCode: Yup.number()
                    .required(t('validationSchema.postalCode')),
                city: Yup.string()
                    .required(t('validationSchema.city')),
                country: Yup.string()
                    .required(t('validationSchema.country')),
                state: Yup.string()
                    .required(t('validationSchema.state')),
            }),
        }),
        onSubmit: (values) => {
            sendDataDonations(values);
        }
    });

    useEffect(() => {
        dispatch(setLoading(true));
        usersService.getDonationsById(id)
            .then((response) => {
                formik.setValues({ ...response.data })
                setCenter({ lat: response.data.address.latitude, lng: response.data.address.longitude });
                setDonation(response.data)
                dispatch(setLoading(false));
            }).catch(error => {
                dispatch(setLoading(false));
            })
    }, [])

    const getAddressComponent = (address_components, type) => {
        let addressComponent = null
        address_components.forEach(component => {
            if (component.types.includes(type)) {
                addressComponent = component.long_name
            }
        })
        return addressComponent
    }

    const onChangeCenter = (center) => {
        const newData = formik.values;
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${center.lat},${center.lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`, {
            "method": "GET",
        }).then(response => response.json())
        .then(async (response) => {
            newData.address = await placeChangeAddress(response.results[0], false);
            newData.address.latitude = center.lat;
            newData.address.longitude = center.lng;
            formik.setValues({ ...newData }, true);
            setCenter(center);
        }).catch(err => console.log(err))
    }

    const placeChangeAddress = async (place, withLatLng = true) => {
        const newData = {
            id: formik.values.address.id,
            street: '',
            postalCode: '',
            externalNumber: '',
            latitude: 0,
            longitude: 0,
            internalNumber: '',
            city: '',
            country: '',
            state: '',
            hometown: '',
            phone: ''
        }


        if (withLatLng) {
            const latLng = await getLatLng(place)
            newData.latitude = latLng.lat;
            newData.longitude = latLng.lng;
            setCenter(latLng);
        }

        newData.street = getAddressComponent(place.address_components, 'route') ? getAddressComponent(place.address_components, 'route') : ''
        newData.externalNumber = getAddressComponent(place.address_components, 'street_number') ? getAddressComponent(place.address_components, 'street_number') : ''
        newData.neighborhood = getAddressComponent(place.address_components, 'sublocality') ? getAddressComponent(place.address_components, 'sublocality') : ''
        newData.municipality = getAddressComponent(place.address_components, 'locality') ? getAddressComponent(place.address_components, 'locality') : ''
        newData.city = getAddressComponent(place.address_components, 'locality') ? getAddressComponent(place.address_components, 'locality') : ''
        newData.state = getAddressComponent(place.address_components, 'administrative_area_level_1') ? getAddressComponent(place.address_components, 'administrative_area_level_1') : ''
        newData.country = getAddressComponent(place.address_components, 'country') ? getAddressComponent(place.address_components, 'country') : ''
        newData.postalCode = getAddressComponent(place.address_components, 'postal_code') ? getAddressComponent(place.address_components, 'postal_code') : ''
        newData.phone = getAddressComponent(place.address_components, 'cell_phone') ? getAddressComponent(place.address_components, 'cell_phone') : ''
        
        return newData;
    }





    const handleSelect = async (value) => {
        dispatch(setLoading(true));

        const results = await geocodeByAddress(value)
        const newData = formik.values;
        newData.address = await placeChangeAddress(results[0]);
        formik.setValues({ ...newData }, true);

        dispatch(setLoading(false));
    };

    const uploadImageRequest = async (file) => {
        let formData = new FormData();
        formData.append("file", file)
        try {
            dispatch(setLoading(true));
            const response = await api.uploadFile(formData)
            setUploadImage(response.data.file)
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false));
        }
    }

    const sendDataDonations = async (data) => {
        try {
            const response = await usersService.updateDonations(id, data);
            console.log(response)
            setSaveLoading(true);
            setSaveLoading(false);
            toast.success(t('donations.label.editedDonation'));
            history.push('/donations');
        } catch (error) {
            setSaveLoading(false);
            console.log(error.response.data.errors[0])
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }

    }

    const handleDeleteDonation = async () => {

        try {
            const respuesta = confirm(t('general.label.deleteRegister'))
            if (respuesta === true) {
                const data = await usersService.deleteDonations(id)
                toast.success(t('donations.label.deletedDonation'));
                history.push('/donations');
            } else {
                return false;
            }
        } catch (err) {
            console.log(err)
        }
    }

    const searchOptions = {
        componentRestrictions: { country: ['mx'] }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('donations.label.donationsEdit')}</h3>
                        <div className="mr-1 card-tools">
                            <Button
                                isLoading={donations}
                                type="submit" block
                                onClick={handleDeleteDonation}
                                className="btn btn-danger"><i className="icon-button fa fa-trash"></i></Button>
                        </div>
                    </div>
                    <div className="p-3">
                        {donation ? <form className="form-horizontal" onSubmit={formik.handleSubmit}>

                            <div className="row">

                                <div className="col-sm-8" >
                                    <div className="form-group row">
                                        <label>{t('donations.label.nameFundation')}</label>
                                        <Input
                                            value={formik.values.name}
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('name')}
                                            type="text"
                                            placeholder={t('donations.label.nameFundation')} />
                                    </div>

                                    <div className="form-group row">
                                        <label>{t('donations.label.descriptionDonation')}</label>
                                        <Textarea
                                            value={formik.values.description}
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('description')}
                                            type="text"
                                            placeholder={t('donations.label.descriptionDonation')} />
                                    </div>

                                    <div className="form-group row">
                                        <label>{t('donations.label.pointsObject')} </label>
                                        <input
                                            disabled
                                            className="form-control"
                                            value={formik.values.pointsGoal}
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('pointsGoal')}
                                            type="number"
                                            placeholder={t('donations.label.pointsObject')} />
                                    </div>
                                    <div className="form-group row">
                                        <label>{t('donations.label.promotorName')}</label>
                                        <Input
                                            value={formik.values.promoterName}
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('promoterName')}
                                            type="text"
                                            placeholder={t('donations.label.promotorName')} />
                                    </div>

                                    <div className="form-group row">
                                        <label>{t('donations.label.contactPhone')}</label>
                                        <Input
                                            value={formik.values.phone}
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('phone')}
                                            placeholder={t('donations.label.contactPhone')} />
                                    </div>

                                </div>

                                <div className="col-sm-4" >

                                    <ImageUpload
                                        id="image-logo"
                                        label={t('donations.label.logoImage')}
                                        image={uploadLogo}
                                        image={formik.values.coverLogo}
                                        witdh={512}
                                        height={512}
                                        hideContent
                                        isLogo
                                        className="profile-user-img img-fluid img-circle"
                                        uploadImage={(file) => uploadImageRequest(file, "logo")}
                                    />

                                    <ImageUpload
                                        label={t('donations.label.imageDonation')}
                                        image={formik.values.coverImage}
                                        witdh={960}
                                        height={640}
                                        uploadImage={uploadImageRequest}
                                    />
                                </div>
                            </div>
                            { /*Direcciones*/}


                            <div className="card">
                                <div className="card-header" id="headingOne">
                                    <h3 className="text-danger font-weight-bold">{t('donations.label.addAddress')}</h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group row">
                                        <PlacesAutocomplete
                                            searchOptions={searchOptions}
                                            value={address}
                                            onChange={setAddress}
                                            onSelect={handleSelect} >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <>
                                                    <input
                                                        autocomplete="off"
                                                        className="form-control "
                                                        {...getInputProps({
                                                            placeholder: t('address.label.addAddress'),
                                                        })}
                                                    />

                                                    <div className="ml- pl-1">
                                                        {loading ? <div> {t('address.label.searching')}</div> : null}

                                                        {suggestions.map((suggestions) => {
                                                            const style = {
                                                                backgroundColor: suggestions.active ? "#2da5dc" : "#F2F2F2",
                                                                cursor: 'pointer'
                                                            }
                                                            return <div {...getSuggestionItemProps(suggestions, { style })} >
                                                                {suggestions.description} </div>
                                                        })}
                                                    </div>

                                                </>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-sm-6">
                                            <div className="mt-4 form-row" >
                                                <div className="form-group col-md-12">
                                                    <label>{t('address.label.street')}</label>
                                                    <Input
                                                        value={formik.values.address.street}
                                                        formik={formik}
                                                        formikFieldProps={formik.getFieldProps('address.street')}
                                                        type="text"
                                                        placeholder={t('address.label.street')} />
                                                </div>
                                            </div>

                                            <div className="form-row" >
                                                <div className="form-group col-md-4">
                                                    <label>{t('address.label.externalNumber')}</label>
                                                    <Input
                                                        value={formik.values.address.externalNumber}
                                                        formik={formik}
                                                        formikFieldProps={formik.getFieldProps('address.externalNumber')}
                                                        type="text"
                                                        placeholder={t('address.label.externalNumber')} />
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label>{t('address.label.InternalNumber')}</label>
                                                    <Input
                                                        value={formik.values.address.internalNumber}
                                                        formik={formik}
                                                        formikFieldProps={formik.getFieldProps('address.internalNumber')}
                                                        type="text"
                                                        placeholder={t('address.label.InternalNumberOptional')} />
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label>{t('address.label.postalCode')}</label>
                                                    <Input
                                                        value={formik.values.address.postalCode}
                                                        formik={formik}
                                                        formikFieldProps={formik.getFieldProps('address.postalCode')}
                                                        type="text"
                                                        placeholder={t('address.label.postalCode')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row" >
                                                <div className="form-group col-md-6">
                                                    <label>{t('address.label.city')}</label>
                                                    <Input
                                                        value={formik.values.address.city}
                                                        formik={formik}
                                                        formikFieldProps={formik.getFieldProps('address.city')}
                                                        type="text"
                                                        placeholder={t('address.label.city')} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>{t('address.label.country')}</label>
                                                    <Input
                                                        value={formik.values.address.country}
                                                        formik={formik}
                                                        formikFieldProps={formik.getFieldProps('address.country')}
                                                        type="text"
                                                        placeholder={t('address.label.country')} />
                                                </div>
                                            </div>

                                            <div className="form-row" >
                                                <div className="form-group col-md-6">
                                                    <label>{t('address.label.state')}</label>
                                                    <Input
                                                        value={formik.values.address.state}
                                                        formik={formik}
                                                        formikFieldProps={formik.getFieldProps('address.state')}
                                                        type="text"
                                                        placeholder={t('address.label.state')} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label> {t('address.label.hometown')} </label>
                                                    <Input
                                                        value={formik.values.address.hometown}
                                                        formik={formik}
                                                        formikFieldProps={formik.getFieldProps('address.hometown')}
                                                        type="text"
                                                        placeholder={t('address.label.hometown')} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            {formik.values.address ?
                                                <Gmap
                                                    center={center}
                                                    onChangeCenter={(center) => onChangeCenter(center)}
                                                />
                                                : null}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/donations`}
                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                </div>

                                <div className="col-sm-2">
                                    <Button
                                        type="submit" block
                                        isLoading={donations}
                                        className="btn btn-danger" > {t('general.buttons.createDonation')} </Button>
                                </div>
                            </div>
                        </form> : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditDonation;
