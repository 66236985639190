import React from 'react';
import * as storeService from '../../../services/stores';
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button, Input} from "@components";
import {useFormik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";

const CreateStore = () => {
    const history = useHistory();
    const [t] = useTranslation();

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required(t('stores.validationSchema.categoryNameRequired'))
        }),
        onSubmit: (values) => {
            save(values);
        }
    })
    
    const save = async (data) => {
        try {
            await storeService.createStore(data)
            toast.success(t('general.label.successRegister'));
            history.push('/stores');
        } catch (error) {
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }
    }
    
    return (
        <div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header p2">
                        <h3 className="text-danger font-weight-bold">{t('menusidebar.label.store.create')}</h3>
                    </div>
                    <div className="p-3">
                        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>{t('stores.label.name')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('name')}
                                        type="text"
                                        placeholder={t('stores.placeholder.name')} />
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/stores`}
                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        type="submit" block
                                        className="btn btn-danger" > {t('stores.buttons.create')} </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default  CreateStore;