import React, { useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import { ContentHeader, Select, Button, Input, Checkbox} from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, useParams, Link } from 'react-router-dom';
import * as usersService from '../../services/users';
import * as Yup from 'yup';

const Profile = () => {
    const [activeTab, setActiveTab] = useState('ACTIVITY');
    const [t] = useTranslation();
    const [user, setUser] = useState();
    const [users, setUsers] = useState(false);

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            password: '',
            email: '',
            lastName: '',
            secondLastName: '',
            isSuperAdmin: false,
            status: 1
        },
        validationSchema: Yup.object({
            userName: Yup.string()
                .min(5, 'Debe tener 5 caracteres o m�s')
                .required('Requerido'),
            password: Yup.string()
                .min(8, 'Debe tener 8 caracteres o m�s'),
            passwordConfirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Las contrase�as deben coincidir'),
            email: Yup.string()
                .email('Correo invalido')
                .required('Requerido'),
            name: Yup.string()
                .min(5, 'Debe tener 5 caracteres o m�s')
                .required('Requerido'),
            lastName: Yup.string()
                .min(5, 'Debe tener 5 caracteres o m�s')
                .required('Requerido'),
            secondLastName: Yup.string(),
            status: Yup.number()
        }),
        onSubmit: (values) => {
            usersData(values);
        }
    });
    
    const getDataUser = localStorage.getItem('datauser');
    const parseData = JSON.parse(getDataUser)
    const idUser = parseData.id

    useEffect(() => {
        usersService.getUserById(idUser)
            .then((response) => {
                formik.setValues({ ...response.data })
                setUser(response.data)
                console.log(response.data)
            }).catch(error => {
                console.log(error)
            })
    }, [])

    const usersData = async (data) => {
        data.status = 1;
        console.log(data)
        try {
            const res = await usersService.updateUser(idUser, data);
            console.log(res)
            toast.success("Perfil editado");
        } catch (error) {
            setUsers(false);
            console.log(error.response.data.errors[0])
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }
    }

    return (
        <>
            <ContentHeader title={t('profile.label.profile')} />
            <div className="text-center">
                <section className="mt-5 content">
                    <div className="container">
                        <div className="form-row">
                            <div className="col-md-4">
                                <div className="card card-primary card-outline">

                                    <div className="card-body box-profile">
                                        <div className="text-center">
                                            <img
                                                className="profile-user-img img-fluid img-circle"
                                                src="/img/default-profile.png"
                                                alt="User profile"
                                            />
                                        </div>
                                        <h3 className="m-4 profile-username text-center">
                                            {parseData.userName || 'Empty user name'}
                                        </h3>
                                        {/*<p className="text-muted text-center">
                                            {parseData.roleNames || 'Empty rol'}
                                        </p>*/}
                                        <ul className="list-group list-group-unbordered mb-3">
                                            <li className="float-right list-group-item">
                                                <span className="ml-1">
                                                    <b>
                                                        {t('users.label.email')}
                                                    </b> {parseData.email || 'Empty email'}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* /.card-body */}
                                </div>

                            </div>
                            <div className="form-group col-md-8" >
                                {user ? <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>{t('admins.label.userName')}</label>
                                            <Input
                                                value={user.userName}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('userName')}
                                                type="text"
                                                disable
                                                placeholder={t('admins.label.userName')} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>{t('login.label.password')}</label>
                                            <Input
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('password')}
                                                type="password"
                                                placeholder={t('login.label.password')} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>{t('users.label.email')}</label>
                                            <Input
                                                value={user.email}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('email')}
                                                type="email"
                                                disable
                                                placeholder={t('users.label.email')} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Confirmar {t('login.label.password')}</label>
                                            <Input
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('passwordConfirmation')}
                                                type="password"
                                                placeholder={t('login.label.password')} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>{t('general.label.name')}</label>
                                            <Input
                                                value={user.name}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('name')}
                                                type="text"
                                                placeholder="Nombre" />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>{t('admins.label.lastName')}</label>
                                            <Input
                                                value={user.lastName}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('lastName')}
                                                type="text"
                                                placeholder={t('admins.label.lastName')} />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>{t('admins.label.motherLastName')}</label>
                                            <Input
                                                value={user.secondLastName}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('secondLastName')}
                                                type="text"
                                                placeholder={t('admins.label.motherLastName')} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                            <Input
                                                value={1}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('status')}
                                                type="hidden" />
                                    </div>
                                    <div className="row p-2 pt-4">
                                        <div className="col-2">
                                            <Link
                                                to={`/users/`}
                                                className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                        </div>
                                        <div className="col-sm-4">
                                            <Button
                                                isLoading={users}
                                                type="submit" block
                                                className="btn btn-danger" > {t('general.buttons.saveChanges')} </Button>
                                        </div>
                                    </div>
                                </form> : null}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Profile;
