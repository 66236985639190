import React, { useState, useEffect } from 'react';
import { Button, Input, ComboBox, Textarea, Checkbox, ImageUpload } from '@components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, useParams, Link } from 'react-router-dom';
import * as NewsServices from '../../services/news';
import * as Yup from 'yup';
import Api from '../../utils/axios'
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';

const EditNews = () => {
    const api = new Api();
    const history = useHistory();
    const [news, setNews] = useState(false);
    const [neew, setNew] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [categories, setCategories] = useState();
    const [uploadImage, setUploadImage] = useState();
    const [tagId, setIdTag] = useState();
    const [t] = useTranslation();
    const { id } = useParams()
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading(true));
        NewsServices.getNewsById(id)
            .then((response) => {
                formik.setValues({
                    name: response.data.name,
                    autor: response.data.autor,
                    details: response.data.details,
                    context: response.data.context,
                    coverImageId: response.data.coverImage.id,
                    readingTime: response.data.readingTime,
                    video: "",
                    tagId: response.data.tag.id,
                    activa: response.data.activa
                })
                setUploadImage(response.data.coverImage);
                setIsActive(response.data.activa);
                setIdTag(response.data.tag.id)
                setNew(response.data)
                api
                    .getTags()
                    .then((response) => {
                        setCategories(response.data)
                        dispatch(setLoading(false));
                    }).catch(error => {
                        dispatch(setLoading(false));
                    })
            }).catch(error => {
                dispatch(setLoading(false));
            })
    }, [])

    const formik = useFormik({
        initialValues: {
            name: "",
            autor: "",
            details: "",
            context: "",
            coverImageId: null,
            readingTime: "",
            video: "",
            tagId: 0,
            activa: false,
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .min(5, t('validationSchema.min5Caracters'))
                .required(t('validationSchema.nameNew')),
            autor: Yup.string()
                .required(t('validationSchema.nameAuthor')),
            details: Yup.string()
                .min(50, t('validationSchema.min50Caracters'))
                .max(450, t('validationSchema.max450Caracters'))
                .required(t('validationSchema.detailsNewsRequired')),
            context: Yup.string()
                .min(130, t('validationSchema.min130Caracters'))
                .max(3000, t('validationSchema.max3000Caracters'))
                .required(t('validationSchema.infoNewRequired')),
            readingTime: Yup.string()
                .matches(/^([0-9])([0-9]):([0-9])([0-9])/, t('validationSchema.formartInvalid'))
                .required(t('validationSchema.redingTimeRequired')),
            tagId: Yup.string()
                .required(t('validationSchema.categoriRequired')),
        }),
        onSubmit: (values) => {
            seenDataNews(values);
        }
    });

    const uploadImageRequest = async (file) => {
        let formData = new FormData();
        formData.append("file", file)
        try {
            dispatch(setLoading(true));
            const response = await api.uploadFile(formData)
            setUploadImage(response.data.file)
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false));
        }
    }

    const seenDataNews = async (data) => {

        if (uploadImage != null)
            data.coverImageId = uploadImage.id;

        if (tagId != undefined)
            data.tagId = tagId;

        data.activa = isActive;
        dispatch(setLoading(true));
        try {

            const response = await NewsServices.updateNews(id, data);
            dispatch(setLoading(false));
            toast.success(t('news.label.newEdit'));
            history.push('/news');
        } catch (error) {
            dispatch(setLoading(false));
            console.log(error.response.data.errors[0])
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }

    }

    const handleDeleteNew = async () => {
        try {
            const respuesta = confirm(t('general.label.deleteRegister'))
            if (respuesta === true) {
                const data = await NewsServices.deleteNews(id)
                toast.success(t('news.label.newDeletd'));
                history.push('/news');
            } else {
                return false;
            }
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('news.label.editNew')}</h3>
                        <div className="mr-1 card-tools">
                            <Button
                                isLoading={news}
                                type="submit" block
                                onClick={handleDeleteNew}
                                className="btn btn-danger"><i className="icon-button fa fa-trash"></i></Button>
                        </div>
                    </div>
                    <div className="p-3">
                        {neew ? <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('news.label.nameNew')}
                                        </label>
                                        <div className="col-sm-10">
                                            <Input
                                                placeholder={t('news.label.nameNew')}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('name')}
                                                type="text"
                                                value={neew.name}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('news.label.abstract')}
                                        </label>

                                        <div className="col-sm-10">
                                            <Textarea
                                                placeholder={t('news.label.abstract')}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('details')}
                                                type="text"
                                                value={neew.details} />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('news.label.context')}
                                        </label>

                                        <div className="col-sm-10">
                                            <Textarea
                                                placeholder={t('news.label.contextDetail')}
                                                formik={formik}
                                                isRichText={true}
                                                formikFieldProps={formik.getFieldProps('context')}
                                                type="text"
                                                value={neew.context} />
                                        </div>
                                    </div>
                                    <div className="form-group row mt-2 mb-2">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('news.label.category')}
                                        </label>
                                        {categories && categories.length > 0 ? (
                                            <div className="col-sm-4">
                                                <ComboBox
                                                    items={categories}
                                                    optionTitle="name"
                                                    onChange={(item) => setIdTag(item.id)}
                                                    value={tagId}
                                                    formik={formik}
                                                    formikFieldProps={formik.getFieldProps('tagId')}
                                                />
                                            </div>
                                        ) : null}

                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-3 col-form-label"
                                        >
                                            {t('news.label.readingTime')}
                                        </label>
                                        <div className="col-sm-3">
                                            <Input
                                                placeholder="Ejm. 02:30"
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('readingTime')}
                                                type="text"
                                                value={neew.readingTime} />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('news.label.authorName')}
                                        </label>
                                        <div className="col-sm-7">
                                            <Input
                                                placeholder={t('news.label.authorName')}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('autor')}
                                                type="text"
                                                value={neew.autor} />
                                        </div>

                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-1 col-form-label"
                                        >
                                            {t('general.label.status')}
                                        </label>
                                        <div className="col-sm-2">
                                            <Checkbox
                                                label="Activa"
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('activa')}
                                                checked={isActive}
                                                onChange={value => setIsActive(value)}
                                            />
                                        </div>

                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <ImageUpload
                                        id="myImage"
                                        label={t('news.label.imageRequired')}
                                        image={uploadImage}
                                        witdh={960}
                                        height={640}
                                        uploadImage={uploadImageRequest}
                                    />
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/news`}
                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        isLoading={news}
                                        type="submit" block
                                        className="btn btn-danger" > {t('general.buttons.saveChanges')} </Button>
                                </div>
                            </div>
                        </form> : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditNews;
