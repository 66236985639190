import Api from '../utils/axios';
const api = new Api();

export const getPromotions = async () => {
    const promotions = await api.getPromotions()
    return promotions;
}

export const createPromotions = async (data) => {
    const promotion = await api.createPromotion(data);
    return promotion;
}

export const deletePromotions = async (id) => {
    const promotion = await api.deletePromotion(id);
    return promotion.data;
}

export const updatePromotions = async (id, data) => {
    const promotion = await api.updatePromotion(id, data);
    return promotion;
}

export const getPromotionsById = async (id) => {
    const promotion = await api.getPromotionById(id);
    return promotion
}