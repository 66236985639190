import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';

import translationEN from './locales/en/translation.json';
import translationTR from './locales/tr/translation.json';
import translationDE from './locales/de/translation.json';
import translationES from './locales/es/translation.json';
import translationFR from './locales/fr/translation.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    tr: {
        translation: translationTR
    },
    es: {
        translation: translationES
    },
    fr: {
        translation: translationFR
    },
    de: {
        translation: translationDE
    }
};

i18n.use(backend)
    .use(languageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'es',
        fallbackLng: 'es',
        lowerCaseLng: true,
        debug: false,
        fallbackNS: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            bindI18n: 'languageChanged',
            bindI18nStore: '',
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            useSuspense: true
        }
    });

export default i18n;
