import Api from '../utils/axios';
const api = new Api();

export const getDonations = async () => {
    const donations = await api.getDonations()
    return donations.data;
}

export const createDonation = async (data) => {
    const CreatedDonation = await api.createDonation(data);
    return CreatedDonation;
}

export const deleteDonations = async (id) => {
    const deleteDonations = await api.deleteDonations(id);
    return deleteDonations.data;
}

export const updateDonations = async (id, data) => {
    const updateDonation = await api.updateDonations(id, data);
    return updateDonation;
}

export const getDonationsById = async (id) => {
    const response = await api.getDonationsById(id);
    return response
}