import Api from '../utils/axios';
const api = new Api();

export const getClients = async () => {
    const client = await api.getClients()
    return client;
}

export const createClient = async (data) => {
    const clientCreated = await api.createClient(data);
    return clientCreated;
}

export const addPoints = async (uid, value) => {
    const response = await api.addPoints(uid, value);
    return response;
}

export const deleteClient = async (uid) => {
    const deleteUs = await api.deleteClient(uid);
    return deleteUs;
}

export const updateClient = async (uid, data) => {
    const updateClieent = await api.updateClient(uid, data);
    return updateClieent;
}

export const getClientById = async (uid) => {
    const foundClient = await api.getClientById(uid);
    return foundClient
}