import { setLoading } from '@app/store/reducers/ui';
import { Button, ComboBox, ImageUpload, Input, Textarea } from '@components';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import * as benefitsService from '../../services/rewords';
import Api from '../../utils/axios';

const EditRewards = () => {
    const api = new Api();
    const history = useHistory();
    const [rewords, setRewards] = useState(false);
    const [reword, setReward] = useState(false);
    const [isSaveLoading, setSaveLoading] = useState(false);
    const [categories, setCategories] = useState();
    const [subCategories, setSubCategories] = useState();
    const [uploadImage, setUploadImage] = useState();
    const [refresh, setRefresh] = useState();
    const [t] = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            name: '',
            imageId: null,
            value: 0,
            description: '',
            categoryId: 0,
            subCategoryId: 0,
            category: null,
            subCategory: null,
            status: 1,
            stock: 0
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(30, t('validationSchema.max30Caracters'))
                .required(t('validationSchema.rewardName')),
            value: Yup.number()
                .required(t('validationSchema.rewardValue')),
            description: Yup.string()
                .max(30, t('validationSchema.max30Caracters'))
                .max(150, t('validationSchema.max150Caracters'))
                .required(t('validationSchema.rewardDescription')),
            status: Yup.number(),
            stock: Yup.number()
                .required(t('validationSchema.rewardStock')),
        }),
        onSubmit: (values) => {
            sendDataReword(values);
        }
    });

    useEffect(() => {
        dispatch(setLoading(true));
        benefitsService.getRewordsById(id)
            .then((response) => {
                formik.setValues({ ...response.data })
                setReward(response.data)
                api
                    .getCategories()
                    .then((response) => {
                        setCategories(response.data)
                        api
                            .getSubCategories()
                            .then((response) => {
                                setSubCategories(response.data)
                                dispatch(setLoading(false));
                            }).catch(error => {
                                dispatch(setLoading(false));
                            })
                    }).catch(error => {
                        dispatch(setLoading(false));
                    })
            }).catch(error => {
                dispatch(setLoading(false));
            })

    }, [])

    const uploadImageRequest = async (file) => {
        let formData = new FormData();
        formData.append("file", file)
        try {
            dispatch(setLoading(true));
            const response = await api.uploadFile(formData)
            const newData = formik.values;
            setUploadImage(response.data.file)
            newData.image = response.data.file;
            formik.setValues({ ...newData }, true);
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false));
        }
    }

    const sendDataReword = async (data) => {

        if (uploadImage != null)
            data.imageId = data.image.id;

        try {
            await benefitsService.updateRewords(id, data);
            setSaveLoading(true);
            setSaveLoading(false);
            toast.success(t('rewards.label.rewardEdited'));
            history.push('/rewards');
        } catch (error) {
            setSaveLoading(false);
            setReward(false)
            console.log(error.response.data.errors[0])
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }

    }

    const handleCategory = (item) => {
        const newData = formik.values;
        newData.category = item;
        newData.categoryId = item.id;
        newData.subCategory = null;
        newData.subCategoryId = null;
        formik.setValues({ ...newData }, true);
    }

    const handleSubCategory = (item) => {
        const newData = formik.values;
        newData.subCategory = item;
        newData.subCategoryId = item ? item.id : null;
        formik.setValues({ ...newData }, true);
    }

    const handleDeleteReword = async () => {
        try {
            const respuesta = confirm(t('general.label.deleteRegister'))
            if (respuesta === true) {
                const data = await benefitsService.deleteReword(id)
                toast.success(t('rewards.label.rewardDeleted'));
                history.push('/rewards');
            } else {
                return false;
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div>
            {/*<ContentHeader title={t('users.label.edit')} buttons={headerButtons} />*/}
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('rewards.label.editReward')}</h3>
                        <div className="mr-1 card-tools">
                            <Button
                                isLoading={rewords}
                                type="submit" block
                                onClick={handleDeleteReword}
                                className="btn btn-danger"><i className="icon-button fa fa-trash"></i></Button>
                        </div>
                    </div>
                    <div className="p-3">
                        {reword ? <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="form-group row">
                                        <label>{t('rewards.label.rewardName')}</label>
                                        <Input
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('name')}
                                            type="text"
                                            placeholder={t('rewards.label.rewardName')}
                                            value={reword.name} />
                                    </div>

                                    <div className="form-group row">
                                        <label>{t('rewards.label.rewarddescription')}</label>
                                        <Textarea
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('description')}
                                            type="text"
                                            placeholder={t('rewards.label.rewarddescription')}
                                            value={reword.description} />
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-3 col-form-label"
                                        >
                                            {t('rewards.label.category')}
                                        </label>

                                        {categories && categories.length > 0 ? (
                                            <div className="col-sm-4">
                                                <ComboBox
                                                    id="comboSub"
                                                    items={categories}
                                                    optionTitle="name"
                                                    onChange={handleCategory}
                                                    value={formik.values.categoryId}
                                                    formik={formik}
                                                    formikFieldProps={formik.getFieldProps('categoryId')}
                                                    placeholder="Categoria" />
                                            </div>
                                        ) : null}

                                        {formik.values.category && formik.values.category.hasSubCategory ? (
                                            <>
                                                <label
                                                    htmlFor="inputName"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    {t('rewards.label.subcategory')}
                                                </label>
                                                <>
                                                    {subCategories && subCategories.length > 0 ? (
                                                        <div className="col-sm-3">
                                                            <ComboBox
                                                                items={subCategories}
                                                                optionTitle="name"
                                                                onChange={handleSubCategory}
                                                                value={formik.values.subCategoryId}
                                                                formik={formik}
                                                                formikFieldProps={formik.getFieldProps('subCategoryId')}
                                                                placeholder="Subcategoria"
                                                                messageOptionDefault="Ninguna"
                                                                hiddenOptionDefault={false}
                                                            />

                                                        </div>

                                                    ) : <p>No se encuentran subcategorias</p>}
                                                </>
                                            </>
                                        ) : null}
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputStock"
                                            className="col-sm-3 col-form-label">{t('rewards.label.quantityExistence')}</label>
                                        <div className="col-sm-4">
                                            <Input
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('stock')}
                                                type="number"
                                                value={reword.stock}
                                                placeholder={t('rewards.label.quantityExistence')} />
                                        </div>

                                        <label
                                            htmlFor="inputValor"
                                            className="col-sm-2 col-form-label">{t('rewards.label.valuePoints')}</label>
                                        <div className="col-sm-3">
                                            <Input
                                                formik={formik}
                                                value={reword.value}
                                                formikFieldProps={formik.getFieldProps('value')}
                                                type="number"
                                                placeholder={t('rewards.label.valuePoints')} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <ImageUpload
                                        label={t('rewards.label.rewardImage')}
                                        image={formik.values.image}
                                        witdh={820}
                                        height={960}
                                        uploadImage={uploadImageRequest}
                                    />
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/rewards`}
                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        className="w-10"
                                        isLoading={rewords}
                                        type="submit"
                                        className="btn btn-danger" > {t('general.buttons.saveChanges')} </Button>
                                </div>
                            </div>
                        </form> : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditRewards;
