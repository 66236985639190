import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form, useField } from "formik";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const editorConfiguration = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'outdent', 'indent'],
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'sck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
        ]
    }
};

const Textarea = ({
    isRichText = false,
    className,
    value,
    icon,
    type = 'text',
    disable = false,
    placeholder,
    autoComplete,
    onChange,
    formik,
    formikFieldProps,
    ...props
}) => {
    const [localValue, setLocalValue] = useState(value || '');
    const [formStatus, setFormStatus] = useState(null);

    useEffect(() => {
        setLocalValue(value || '');
    }, [value]);

    useEffect(() => {
        if (onChange && localValue !== value) {
            onChange(localValue);
        }
        if (formik && formikFieldProps) {
            formik.setFieldValue(formikFieldProps.name, localValue);
        }
    }, [localValue]);

    useEffect(() => {
        if (
            formik.touched[formikFieldProps.name] &&
            formik.errors[formikFieldProps.name]
        ) {
            setFormStatus('is-invalid');
            return;
        }
        if (
            formik.touched[formikFieldProps.name] &&
            !formik.errors[formikFieldProps.name]
        ) {
            setFormStatus('is-valid');
            return;
        }
        setFormStatus(null);
    }, [
        formik.touched[formikFieldProps.name],
        formik.errors[formikFieldProps.name]
    ]);

    const onChangeHandler = (event) => {
        event.preventDefault();
        setLocalValue(event.target.value || '');
    };

    const onChangeHandlerRich = (data) => {
        setLocalValue(data || '');
    };

    const printFormError = () => {
        if (
            formik.touched[formikFieldProps.name] &&
            formik.errors[formikFieldProps.name]
        ) {
            return (
                <div
                    className="invalid-feedback"
                    style={{ display: 'inline-block' }}
                >
                    {formik.errors[formikFieldProps.name]}
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <div className={`input-group${className ? ` ${className}` : ''}`}>
                {!isRichText ? <textarea
                    type={type}
                    className={`form-control${formStatus ? ` ${formStatus}` : ''
                        }`}
                    placeholder={placeholder}
                    value={localValue}
                    autoComplete={autoComplete}
                    {...props}
                    onChange={onChangeHandler}
                    disabled={disable}
                    style={{ minHeight: '80px', height: '100px' }}
                /> :
                    <CKEditor
                        id="editor-rich-text"
                        editor={ClassicEditor}
                        data={localValue}
                        config={editorConfiguration}
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            //console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ event, editor, data });
                            onChangeHandlerRich(data);
                        }}
                    />
                }

                {icon ? (
                    <div className="input-group-append">
                        <div className="input-group-text">
                            <FontAwesomeIcon icon={icon} />
                        </div>
                    </div>
                ) : null}
            </div>
            {formik && formikFieldProps ? printFormError() : null}
        </>
    );
};

export default Textarea;
