import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Button } from '@components';
import { useHistory } from 'react-router-dom';
import * as usersService from '../../services/tags';
import Spinner from 'react-bootstrap/Spinner'
import prettyDate from '../../utils/pretty-date'

import { customStyles, paginationComponentOptions } from '../../utils/table-config'
import DataTable from 'react-data-table-component';

const headerButtons = [
    {
        text: "Agregar nueva categoría",
        urlSlug: "/news/categories/create",
    }
];

const Tags = () => {
    const [t] = useTranslation();
    const [tags, setTags] = useState([])
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()
    const columns = useMemo(() => [
        {
            name: <b>Id</b>,
            selector: row => row.id,
            sortable: true,
        },
        {
            name: <b>{ t('general.label.name')}</b>,
            selector: row => row.name,
            sortable: true,
        },
        {
            name: <b>{t('general.label.dateOfCreated')}</b>,
            selector: row => row.createdAt,
            sortable: true,
            format: row => prettyDate(row.createdAt),
        },
        {
            name: '',
            cell: (row) => <Button className="m-1 btn btn-danger" onClick={() => editTag(row.id)} id={row.id} ><i className="icon-button fa fa-edit"></i></Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]);

    useEffect(() => {
        if (loading) {
            usersService.getTags()
                .then(response => {
                    setTags(response.data)
                    console.log(response.data)
                    setLoading(false)
                    setIsLoading(true)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }
    }, [])

    const editTag = (id) => {
        history.push(`/news/categories/edit/${id}`)
    }

    return (
        <div className="container-fluid">
            <ContentHeader title={t('menusidebar.label.tags')} buttons={headerButtons} />

            {tags && tags.length > 0 ? (
                <DataTable
                    data={tags}
                    customStyles={customStyles}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    highlightOnHover={true}
                    columns={columns}
                />
            ) : <div>
                    <h1 className="text-center">{t('general.label.noRegisters')}</h1>
            </div>}

            <div className="mx-auto text-center mt-5">
                {isLoading ? tags && tags.length > 0 : <Spinner animation="border" />}
            </div>

        </div>
    );
};

export default Tags;