import React, { useState, useEffect } from 'react';
import * as storesService from '../../../services/stores';
import {Link, useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Button, Input} from "@components";
import {useFormik} from "formik";
import Api from '../../../utils/axios'
import * as Yup from "yup";
import {setLoading} from "@store/reducers/ui";
import {toast} from "react-toastify";
import * as usersService from "@app/services/users";
import Autocomplete from "react-autocomplete";
import {prettyPrice} from "@app/utils/string-manipulation";

const EditStore = () => {
    const api = new Api();
    const history = useHistory();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [products, setProducts] = useState([]);
    const [storeLoading, setStoreLoading] = useState();
    const [productInput, setProductInput] = useState('');
    const { id } = useParams()
    
    const formik = useFormik({
        initialValues: {
            id: '',
            nombre: '',
            products: []
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .required(t('stores.validationSchema.categoryNameRequired'))
        }),
        onSubmit: (values) => {
            save(values);
        }
    })
    
    const handleDelete = async () => {
        try {
            const respuesta = confirm(t('general.label.deleteRegister'))
            
            if (respuesta === true) {
                const data = await storesService.deleteStore(id);
                if (data.successful)
                    toast.success(t('stores.errors.deleted'));

                history.push('/stores');
            } else {
                return false;
            }
        } catch (err) {
            toast.error('Hubo un problema al eliminar la tienda');
        }
    }
    
    const save = async (data) => {
        console.log(data)
        try {
            const respuesta = confirm(t('general.label.deleteRegister'))
            if (respuesta !== true) {
                return false;
            }
            const newProducts = data.products.map(p => {
                return {
                    cadenaId: id,
                    productId: p.id
                }
            })
            
            const newData = {
                name: data.nombre,
                products: newProducts
            }
            await storesService.updateStore(id, newData)
            if (data.successful)
                toast.success(t('admins.label.adminDeleted'));
            
            history.push('/stores');
        } catch (err) {
            const errorMessage = err.response.data.errors[0]
            toast.error(errorMessage);
            toast.error('Hubo un problema al eliminar la tienda');
        }
    }
    
    const handlerProduct = (upc, index) => {
        const product = products.find(item => (item.upc + ' - ' + item.descripcion + ' - ' + item.ticket).includes(upc));
        const currentProduct = formik.values.products.find(item => (item.upc + ' - ' + item.descripcion + ' - ' + item.ticket).includes(upc));
        
        if (currentProduct) {
            toast.error('Ya se encuentra el producto asignado a la tienda.');
            return;
        }
        
        const newProduct = {
            ...product
        }
        
        const newData = formik.values;
        newData.products.push(newProduct)
        formik.setValues({ ...newData }, true);

        products.splice(index, 1);
    }
    
    const handleRemoveProduct = (index) => {
        const newData = formik.values;
        newData.products.splice(index, 1)
        formik.setValues({ ...newData }, true);
    }

    useEffect(() => {
        storesService.getStoreById(id)
            .then((response) => {
                response.data.products = []
                // formik.setValues({ ...response.data })
                setStoreLoading(response.data)
                api
                    .getProduct(response.data.id)
                    .then((responseProducts) => {
                        api
                            .getProduct()
                            .then((responseAllProducts) => {
                                const newData = formik.values;
                                
                                newData.id = response.data.id;
                                newData.nombre = response.data.nombre;
                                newData.products = responseProducts.data;
                                console.log(newData.products)
                                
                                let productsAux = responseAllProducts.data.filter(p => !newData.products.some(item => item.id === p.id));
                                setProducts(productsAux)
                                formik.setValues({ ...newData }, true);
                            }).catch(error => {
                            console.log(error)
                        })
                    }).catch(error => {
                    console.log(error)
                })
                dispatch(setLoading(false));
            }).catch(error => {
            console.log(error)
        })
    }, [])

    useEffect(() => {
        api
            .getProduct()
                .then((responseProducts) => {
                    setProducts(responseProducts.data)
                }).catch(error => {
                    console.log(error)
                })
    }, [])
    
    return (
        <div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header p2">
                        <h3 className="text-danger font-weight-bold">{t('stores.title.edit')}</h3>
                        <div className="mr-1 card-tools">
                            <Button
                                type="submit" block
                                onClick={handleDelete}
                                className="btn btn-danger"><i className="icon-button fa fa-trash"></i></Button>
                        </div>
                    </div>

                    <div className="p-3">
                        { storeLoading?

                            <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>{t('stores.label.name')}</label>
                                        <Input
                                            value={storeLoading.nombre}
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('nombre')}
                                            type="text"
                                            placeholder={t('stores.placeholder.name')} />

                                        <div className="dataTableAddProduct">
                                            <div className="card-header p2">
                                                <h3 className="text-danger font-weight-bold titleSectionProduct">{t('stores.title.list')}</h3>
                                            </div>
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Id</th>
                                                    <th scope="col">{t('tickets.label.nameProduct')}</th>
                                                    <th scope="col"></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {formik.values.products && formik.values.products.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.id}</td>
                                                        <td>{item.ticket ? item.upc + ' - ' + item.descripcion + ' - ' + item.ticket : item.upc + ' - ' + item.descripcion}</td>
                                                        <td>
                                                            <Button
                                                                onClick={() => handleRemoveProduct(index)} block
                                                                className="btn btn-danger" > {t('general.buttons.delete')} </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div className="form-group col-md-6">
                                        <div className="card-header -p2">
                                            <h3 className="text-danger font-weight-bold titleSectionProduct">{t('stores.title.addProduct')}</h3>
                                        </div>
                                        <div className="addProductSection">
                                            <label className="">
                                                {t('tickets.label.nameProduct')}
                                            </label>

                                            {products && products.length > 0 ? (
                                                <div>
                                                    <Autocomplete
                                                        getItemValue={(item) => item.ticket ? item.upc + ' - ' + item.descripcion + ' - ' + item.ticket : item.upc + ' - ' + item.descripcion }
                                                        items={products}
                                                        renderInput={(props) => {
                                                            return <input
                                                                type="text"
                                                                className="form-control"
                                                                {...props}
                                                            />
                                                        }}
                                                        wrapperStyle={{
                                                            display: 'block'
                                                        }}
                                                        shouldItemRender={(item, value) => (item.upc + ' - ' + item.descripcion + ' - ' + item.ticket).toLowerCase().indexOf(value.toLowerCase()) > -1}
                                                        renderItem={(item, isHighlighted) =>
                                                            <div key={item.upc} style={{ background: isHighlighted ? 'lightgray' : 'white', zIndex: '9', padding: '6px' }}>
                                                                {item.ticket ? item.upc + ' - ' + item.descripcion + ' - ' + item.ticket : item.upc + ' - ' + item.descripcion}
                                                            </div>
                                                        }
                                                        onSelect={(val) => handlerProduct(val)}
                                                        onChange={(e) => setProductInput(e.target.value)}
                                                        menuStyle={{
                                                            borderRadius: '3px',
                                                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                                            background: 'rgba(255, 255, 255, 0.9)',
                                                            padding: '2px 0',
                                                            fontSize: '90%',
                                                            position: 'fixed',
                                                            overflow: 'auto',
                                                            maxHeight: '50%',
                                                            height: '250px',
                                                            zIndex: '9',
                                                        }}
                                                        value={productInput}
                                                    />
                                                    <table className="table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Id</th>
                                                            <th scope="col">{t('tickets.label.nameProduct')}</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {products && products.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.id}</td>
                                                                <td>{item.ticket ? item.upc + ' - ' + item.descripcion + ' - ' + item.ticket : item.upc + ' - ' + item.descripcion}</td>
                                                                <td>
                                                                    <Button
                                                                        onClick={() => handlerProduct(item.upc, index)} block
                                                                        className="btn btn-danger" > Agregar </Button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-2 pt-4">
                                    <div className="col-2">
                                        <Link
                                            to={`/stores`}
                                            className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                    </div>
                                    <div className="col-sm-2">
                                        <Button
                                            type="submit" block
                                            className="btn btn-danger" > {t('general.buttons.saveChanges')} </Button>
                                    </div>
                                </div>
                            </form>
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default  EditStore;